import * as React from "react";
import {Box, CircularProgress, Tab, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as GreyScale from "../../shared/Theme/GreyScale";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useContext, useEffect, useMemo, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {downloadDocument, getDocumentation} from "../../query/entities/documentation";
import QuantifeelSvgIcon from "../../shared/components/QuantifeelSvgIcon/QuantifeelSvgIcon";
import {ReactComponent as SaveAlt} from '../../img/icons/save_alt.svg'
import {SiteContext} from "../../Context";
import {getCustomerTypes} from "../../query/entities/customers";
import Button from "@mui/material/Button";
import translations from '../../translations/en.json';
import ProgressWrapper from "../../shared/components/ProgressWrapper";
import {useSnackbar} from "notistack";
import chroma from 'chroma-js'
import axios from 'axios'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    pageContainer: {
        width: '100%',
        height: '100%',
    },
    header: {
        width: "100%",
        height: '90px',
        backgroundColor: GreyScale.GREY_7,
        color: GreyScale.GREY_1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    parentCategoryContainer: {
        borderBottom: 1,
        borderColor: 'divider',
        width: '100%'
    },
    categoryContainer: {
        padding: '24px',
        borderBottom: '2px solid lightgrey',
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        },
        '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap'
        }
    },
    category: {
        textTransform: 'none',
        height: '100px',
        borderRadius: '12px',
        boxShadow: `2px 2px 2px ${GreyScale.GREY_2}`,
        margin: '24px 12px 24px 12px',
        backgroundColor: GreyScale.GREY_1,
        color: theme.palette.text.primary,
        fontWeight: "bold",
        fontSize: '16px',
        '&.Mui-selected': {
            border: `1px solid ${theme.palette.red}`,
            backgroundColor: chroma(theme.palette.red).alpha(.1).css(),
            color: theme.palette.red,
        },
    },
    tabPanel: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    categoryTableContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '28px'
    },
    tableRowContainer: {
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 24px 16px 24px',
        borderBottom: '1px solid grey',
    },
    pageLoading: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: GreyScale.GREY_1,
        opacity: 0.8,
    },
}))

const BEVERAGE = "Beverage";
const PHARMA = "Pharma";

const HelpCenter = () => {

    const {userDetails} = useContext(SiteContext);
    const {enqueueSnackbar} = useSnackbar();

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [customerType, setCustomerType] = useState("");
    const [downloadButtonsLoadingState, setDownloadButtonsLoadingState] = useState({});

    let fileDownloaded = {};

    // Get all documents
    const {isLoading: isLoadingDocuments, data: documents} = useQuery(['documents'], getDocumentation);

    // Get customerTypes to determine which type the current user is (only given customerType id in userDetails)
    const {isLoading: isLoadingCustomerTypes, data: customerTypes} = useQuery(['customerTypes'], getCustomerTypes)

    // Handles tab selection
    const handleCategorySelection = (e, newVal) => {
        setSelectedCategory(newVal);
    }

    // Filter the documents based off the selectedCategory and the customerType
    const filterDocs = () => {
        if(!!(documents)){
            if(customerType === BEVERAGE || customerType === PHARMA) {
                return documents.filter(doc => doc.category === selectedCategory && (doc.customerType?.name === customerType || !(doc.customerType?.name)))
            }
            return documents.filter(doc => doc.category === selectedCategory);
        }
    }

    // Memo to keep the filtered documents
    const filteredDocs = useMemo(() => filterDocs(), [selectedCategory, customerType, documents]); // eslint-disable-line react-hooks/exhaustive-deps

    // Starts the download process
    const handleDownload = (doc)=>{

        setDownloadButtonsLoadingState(prevState => ({ ...prevState, [doc.id]: true }));

        fileDownloaded = doc;
        mutateDownload(doc.downloadUrl);
    }

    // Mutate
    const {mutate:mutateDownload} = useMutation(downloadDocument, {
        onSuccess: (data) => {

            // Get document, given data.url...
            axios.get(data.url, { responseType: 'blob' })
              .then((response) => {

                  try {
                      // Create a link and download the document to the device...
                      const link = document.createElement('a');
                      const blob = new Blob([response.data], { type: response.headers['content-type'] });
                      link.href = window.URL.createObjectURL(blob);
                      link.setAttribute('download', fileDownloaded.name);
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                  } catch (e) {
                      // UI notification...
                      enqueueSnackbar(e.message, {variant: 'error'});
                  }

              })
              .catch((e) => {
                  // UI notification...
                  enqueueSnackbar(e.message, {variant: 'error'});
              })
              .finally(() => {
                  setDownloadButtonsLoadingState(prevState => ({ ...prevState, [fileDownloaded.id]: false }));
              })

        },
        onError: (error) => {
            let errorMessage = error.message;
            if(error.response?.status === 404){
                errorMessage = translations.pages.helpCenter.documentNotFound;
            }
            enqueueSnackbar(errorMessage, {variant: 'error'});
            setDownloadButtonsLoadingState(prevState => ({ ...prevState, [fileDownloaded.id]: false }));
        }
    });


    // UseEffects
    useEffect(() => {
        document.title = translations.pages.helpCenter.pageTitle;
    }, [])

    useEffect(() => {
        if(categories.length > 0) {
            setSelectedCategory(categories[0])
        }
    }, [categories])

    useEffect(() => {
        if(!!(documents) && customerTypes) {
            // Find the unique categories given by the getDocuments api call
            let customerTypeName = customerTypes.find(type => type.id === userDetails.customer.customerTypeId).name;
            setCustomerType(customerTypeName);

            let documentsFilteredByCustomerType = documents;
            if(customerTypeName === BEVERAGE || customerTypeName === PHARMA) {
                documentsFilteredByCustomerType = documents.filter(doc => doc.customerType?.name === customerTypeName || !(doc.customerType?.name));
            }

            const propertyValues = documentsFilteredByCustomerType.map(obj => obj['category']);
            const uniqueValuesSet = new Set(propertyValues);
            const uniqueValuesArray = Array.from(uniqueValuesSet);

            setCategories(uniqueValuesArray);
        }

    }, [documents, customerTypes, userDetails.customer.customerTypeId])

    // Renders
    const renderHeader = () => {
        return (
            <div id="header" className={classes.header}>
                <Typography variant="h4">{translations.pages.helpCenter.pageTitle}</Typography>
            </div>
        )
    }

    /*
        Creates the TabContext and the tabs within.
     */
    const renderCategoryList = () => {
        return (
            <>
                {(isLoadingDocuments || isLoadingCustomerTypes) &&
                    <div className={classes.pageLoading}>
                        <CircularProgress size={40} />
                    </div>
                }
                {selectedCategory && <TabContext value={selectedCategory}>
                    <Box className={classes.parentCategoryContainer}>
                        <TabList onChange={handleCategorySelection} className={classes.categoryContainer} centered>
                            {categories.map((category, i) => {
                                return <Tab key={i} className={classes.category} label={category} value={category} sx={{
                                    width: {xs: 145, sm: 145, md: 220, lg: 220, xl: 270}
                                }}/>
                            })}
                        </TabList>
                    </Box>
                    {renderCategoryPanel()}
                </TabContext>}
            </>
        )
    }

    /*
        Creates all the category tab panels
     */
    const renderCategoryPanel = () => {
        return (
            <>
                {categories.map((category, i) => {
                    return (
                        <TabPanel key={i} value={category} className={classes.tabPanel}>
                            {renderCategoryPanelTable()}
                        </TabPanel>
                    )
                })}
            </>
        )
    }

    /*
        Creates the table that is contained inside each category tab.
        Depending on what customer type the user is will determine whether the user sees documents grouped by either pharma or beverage or all customer-type documents.
     */
    const renderCategoryPanelTable = () => {
        let isPharmaOrBeverage = customerType === BEVERAGE || customerType === PHARMA;

        if(!isPharmaOrBeverage) {

            const categorizedItems = filteredDocs.reduce((acc, item) => {
                let categoryName = item.customerType?.name

                // If customerType is null, make category an empty string ("")
                if(!categoryName) {
                    categoryName = "";
                }

                // Check if the category already exists
                if (!acc[categoryName]) {
                    // If not, create an array for this category
                    acc[categoryName] = [];
                }


                acc[categoryName].push(item);
                return acc;
            }, {});

            return Object.entries(categorizedItems).map(([category, items], i) => {
                return (
                    <div key={i} className={classes.categoryTableContainer}>
                        <Typography>{category}</Typography>
                        {items.map((doc, index) => (
                            <div key={index} className={classes.tableRowContainer}>
                                <Typography>{doc.name}</Typography>
                                <Button onClick={() => handleDownload(doc)} sx={{textTransform: 'none'}}>
                                    <ProgressWrapper isLoading={downloadButtonsLoadingState[doc.id]}>
                                        <Box gap={1} display={'flex'}>
                                            <Typography>{translations.pages.helpCenter.download}</Typography>
                                            <QuantifeelSvgIcon component={SaveAlt} viewBox="0 0 38 38"
                                            />
                                        </Box>
                                    </ProgressWrapper>
                                </Button>
                            </div>
                        ))
                        }
                    </div>
                )
            })
        }

        return filteredDocs.map((doc, i) => {
            return (
                <div key={i} className={classes.tableRowContainer}>
                    <Typography>{doc.name}</Typography>
                    <Button onClick={() => handleDownload(doc)} sx={{textTransform: 'none'}}>
                        <Box gap={1} display={'flex'}>
                            <Typography>{translations.pages.helpCenter.download}</Typography>
                            <QuantifeelSvgIcon component={SaveAlt} viewBox="0 0 38 38"
                            />
                        </Box>
                    </Button>
                </div>
            )
        })
    }

    const classes = useStyles();

    return (
        <div className={classes.root} data-testid="docs-page">
            <div className={classes.pageContainer}>
                {renderHeader()}
                {renderCategoryList()}
            </div>
        </div>
    )
}

export default HelpCenter;