import * as React from "react";
import {useContext, useEffect} from "react";
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import AdminCard from "../../shared/components/AdminCard/AdminCard";

import {SiteContext} from "../../Context";

import {ReactComponent as PlantIcon} from '../../img/icons/plant.svg';
import {ReactComponent as LineIcon} from '../../img/icons/line map.svg';
// import {ReactComponent as LineMapIcon} from '../../img/icons/line map-1.svg';
import {ReactComponent as UsersIcon} from '../../img/icons/users.svg';
import {ReactComponent as CustomerIcon} from '../../img/icons/customer-satisfaction.svg';
import {ReactComponent as HubsIcon} from '../../img/icons/hubs.svg';
import {ReactComponent as DroneIcon} from '../../img/icons/Drone.svg';
import {ReactComponent as SensorIcon} from '../../img/icons/Sensors.svg';

const UsersAndRoles = [
  {
    icon: UsersIcon,
    label: 'Users',
    link: '/users',
    viewBox: '0 0 63 35',
    access: { method: 'update', entity: 'CustomerUser' } 
  },
  {
    icon: CustomerIcon,
    label: 'Customers',
    link: '/list-customers',
    viewBox: '0 0 45 44',
    access: { method: 'get', entity: 'Customer', access: 'Platform' }
  },
]

const PlantsAndLines = [
  {
    icon: PlantIcon,
    label: 'Plants',
    link: '/list-plants',
    viewBox: '0 0 32 32',
    access: { method: 'update', entity: 'Plant' }
  },
  {
    icon: LineIcon,
    label: 'Lines',
    link: '/list-lines',
    viewBox: '0 0 32 32',
    access: { method: 'update', entity: 'Line' }
  }
  // {
  //   icon: LineMapIcon,
  //   label: 'Line Maps',
  //   link: '/list-line-maps',
  //   viewBox: '0 0 32 32'
  // }
];

// const Settings = [
//   {
//     label: 'Graph Settings',
//     link: '/',
//   },
//   {
//     label: 'Unit Settings',
//     link: '/',
//   }
// ];

const DronesSennsorsHubs = [
  {
    icon: SensorIcon,
    label: 'Sensors',
    link: '/list-sensors',
    viewBox: '0 0 32 32',
    access: { method: 'update', entity: 'Multisensor' }
  },
  {
    icon: HubsIcon,
    label: 'Hubs',
    link: '/list-hubs',
    viewBox: '0 0 32 32',
    access: { method: 'get', entity: 'Hub', access: 'Platform' }
  },
  {
    icon: DroneIcon,
    label: 'Drones',
    link: '/list-drones',
    viewBox: '0 0 32 32',
    access: { method: 'get', entity: 'Drone', access: 'Platform' }
  }
]
const Administration = (props) => {

    const {hasPermission} = useContext(SiteContext);

    useEffect(() => {
      document.title = "Administration";
    }, [])


    const useStyles = makeStyles({
      root: {
        height: '100%',
        width: '100%',
        backgroundColor: '#FBFBFB',
        overflowY: 'auto',
        paddingBottom: '30px'
      },
      header: {
        backgroundColor: 'white',
        height: '80px',
        fontSize: '20px',
        lineHeight: '48px',
        padding: '16px',
        borderBottom: '1px solid #E0E0E0'
      },
      cardContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        padding: '16px 16px 0 16px',
        gap: '16px',
        '& > *': {
          flexGrow: 1
        }
      }
    });
    const classes = useStyles();
    return (
        <div className={classes.root}>
          <Typography className={classes.header}>Administration</Typography>
          <div className={classes.cardContainer}>  
            <AdminCard name="User Management" children={UsersAndRoles} authFn={hasPermission}/>
            <AdminCard name="Plants and Line Management" children={PlantsAndLines} authFn={hasPermission}/>
          </div>
          <div className={classes.cardContainer}>
            {/* <AdminCard name="Settings Management" children={Settings} /> */}
            <AdminCard name="Drones, Hubs & Sensors Management" children={DronesSennsorsHubs} authFn={hasPermission}/>
          </div>          
        </div>
    );
};

export default Administration;
