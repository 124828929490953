import React, {useState} from 'react';
import { makeStyles } from '@mui/styles';
import {ReactComponent as CollapseIcon} from '../../../img/icons/Collapse.svg'
import SideMenuItem from './SideMenuItem';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: ({collapsed}) => collapsed ? '80px' : '204px',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.darkBlue,
    transition: 'width 150ms linear',
    overflow: 'hidden',
    borderRight: `1px solid ${theme.palette.darkGrey}`
  },
  bottomSection: {
    borderTop: `1px solid ${theme.palette.darkGrey}`
  },

}));

const SideMenu = (props) => {
  const {highlight, authFn} = props;
  const [collapsed, setCollapsed] = useState(false);

  const classes = useStyles({collapsed});

  const topSection = props.menuItems?.filter((item) => item.section === 'top') || [];
  const bottomSection = props.menuItems?.filter((item) => item.section === 'bottom') || [];

  return <div className={classes.container}>
    <div className={classes.topSection}>
      {topSection.map(item => item.component({collapsed, highlight}))}
    </div>
    <div className={classes.bottomSection}>
      {bottomSection.map(item => item.component({collapsed, highlight}, () => {setCollapsed(false)}, authFn))}
      <SideMenuItem icon={CollapseIcon} label="Collapse Menu" collapsed={collapsed} rotateOnCollapsed onClick={()=>setCollapsed(!collapsed)}/>
    </div>
  </div>
}

export default SideMenu;