import React, {useContext, useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useSnackbar} from 'notistack';
import {useHistory} from 'react-router-dom';
import {SiteContext} from "../../Context";
import queryString from "query-string";
import {LOCAL_STRINGS} from "../../Constants";
import CreateEditWrapper from "../../shared/components/CreateEdit/CreateEditWrapper";
import {MenuItem, TextField, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import SSTDropdown from "../../shared/components/SSTDropdown";
import RolesManager, {
    computeRoleDelta,
    genNormalizedUserRoles,
    genUserRolesFromNormalizedUserRoles,
    validateRoles
} from "../../shared/components/RolesManager/RolesManager";

import {
    createCustomerUser,
    deleteCustomerUser,
    getCustomerLines,
    getCustomerPlants,
    getCustomerUser,
    getRoles,
    getTimezones,
    searchUser,
    updateUserAndRoles
} from '../../query/queries';
import { PERMISSION_METHOD_INSERT, handlePermissionRedirect, PERMISSION_METHOD_GET } from "../../shared/Utilities";
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from '../../shared/components/ProgressButton/ProgressButton'

const acceptablePagePermission = [
  {entity: 'CustomerUser', method: PERMISSION_METHOD_INSERT, modifier: ''},
  {entity: 'User', method: PERMISSION_METHOD_INSERT, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const emptyUser = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  isAdministrator: false,
  isEnabled: true,
  password: "",
  customerId: ""
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  content: {
    width: '100%',
    padding: '20px',
    margin: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto'
  },
  alreadyExists: {
    color: 'red',
    fontStyle: 'italic'
  },
  userInfo:{
    padding: '0px 20px'
  },
  formControl: {
    marginBottom: '30px',
    fontSize: '20px',
    width: '49%',
    marginLeft: '1%'
  },
  divider: {
    height: '1px',
    backgroundColor: 'grey',
    width: '100%'
  },
  roleManager: {
    marginTop: '30px'
  }
});

const CreateAndEditUser = (props) => {

  const {setBreadcrumbs, currentCustomer, onPlatformCustomer, hasPermission} = useContext(SiteContext);
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [isEdit, setIsEdit] = useState(false);

  const [timeZoneError, setTimeZoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [user, setUser] = useState(emptyUser);
  const [existingUser, setExistingUser] = useState('');
  const [userRoles, setUserRoles] = useState()
  const [confirmPassword, setConfirmPassword] = useState("");
  
  const {isLoading: isLoadingTimezones, data: timezones=[]} = useQuery(['timezones'], getTimezones);
  const {isLoading: isLoadingPlants, data: plants=[]} = useQuery(['plants', {customerId: currentCustomer}], getCustomerPlants, {
    onError: null
  });
  const {isLoading: isLoadingLines, data: lines=[]} = useQuery(['lines', {customerId: currentCustomer}], getCustomerLines, {
    onError: null
  });
  const {isLoading: isLoadingRoles, data: roles} = useQuery(['roles', {onPlatformCustomer}], getRoles);
  const {isLoading: isLoadingUser, data: userData} = useQuery(['customerUser', {userId: user.userId, customerId: currentCustomer}], getCustomerUser, {enabled: !!user.userId});
  
  const pageTitle = history.location.pathname === '/create-user' ? 'Create User' : 'Edit User';

  const {isLoading: isCreating, mutate: mutateCreate} = useMutation(createCustomerUser, {
    onSuccess: (data) => {
      enqueueSnackbar('User Created', {variant: 'success'});
      queryClient.removeQueries(['customerUsers', {customerId: currentCustomer}]);
      history.push(`/users`);
    },
    onError: ({response: {data}}) => {
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  });

  const {isLoading: isUpdating, mutate: mutateUpdate} = useMutation(updateUserAndRoles, {
    onSuccess: (data) => {
      enqueueSnackbar('User Updated', {variant: 'success'});
      queryClient.removeQueries(['customerUser', {userId: user.userId, customerId: currentCustomer}]);
      queryClient.removeQueries(['customerUsers', {customerId: currentCustomer}]);
    },
    onError: ({response: {data}}) => {
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  })

  const {isLoading: isSearching, mutate: mutateSearch} = useMutation(searchUser, {
    onSuccess: (data) => {
      if(data.length > 0){
        setExistingUser(data[0]);
      }
      else{
        setExistingUser('');
      } 
    },
    onError: ({response: {data}}) => {
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  })

  const {isLoading: isDeleting, mutate: mutateDelete} = useMutation(deleteCustomerUser, {
    onSuccess: (data) => {
      enqueueSnackbar('User Deleted', {variant: 'success'});
      queryClient.removeQueries(['customerUser', {userId: user.userId, customerId: currentCustomer}]);
      queryClient.removeQueries(['customerUsers', {customerId: currentCustomer}]);
      history.push('/users');
    },
    onError: ({response: {data}}) => {
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  })


  useEffect(() => {
    if(userData){
      setUser(u => ({...u, ...userData}));
    }
  }, [userData]);

  useEffect(() => {
    if(userData?.customerUserRoles && roles){;
      setUserRoles(genNormalizedUserRoles(userData.customerUserRoles, roles));
    }
  }, [userData, roles])

  useEffect(() => {

    const qs = queryString.parse(props.location.search);

    if (qs.userId) {
      document.title = "Edit User";
      setIsEdit(true);
      setUser(u => ({...u, userId: qs.userId}));
      setBreadcrumbs([{title: "Edit User"}]);
    } else {
      document.title = "Create User";
      setUser(emptyUser);
      setIsEdit(false)
      setBreadcrumbs([{title: "Create User"}]);
    }
  }, [props.location.search, setBreadcrumbs]);
  
  const submit = (e) => {
    e.preventDefault();

    if(existingUser){
      mutateCreate({userId: existingUser, customerId: currentCustomer})
    }
    else {
      setTimeZoneError(user.tz === "");
      setPasswordError(user.password !== confirmPassword);    

      if (user.tz !== "") {
        if(isEdit){
          if(!validateRoles(userRoles)){
            enqueueSnackbar('Please ensure all role fields are populated', {variant: 'error'});
            return;
          }
          const roleDelta = computeRoleDelta(user.customerUserRoles, genUserRolesFromNormalizedUserRoles(userRoles, roles));
          mutateUpdate({customerUser: user, delta: roleDelta})
        }
        else{
          if(user.password === confirmPassword){
            mutateCreate({...user, customerId: currentCustomer})
          }        
        }
      }
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to delete the user "${user.firstName} ${user.lastName}"?`)) {
      mutateDelete(user); 
    }    
  }

  const updateFormData = (update) => {
    setUser({...user, ...update});
  }

  const handleRoleUpdate = (newUserRoles) => {
    setUserRoles(newUserRoles);
  }

  const handleSearch = (emailAddress) => {
    if(emailAddress){
      mutateSearch(emailAddress)
    }
  }

  const classes = useStyles();
  const isLoading = (isLoadingTimezones || isLoadingUser || isUpdating || isCreating || isSearching || isDeleting)
  const isLoadingRoleStuff = (isLoadingUser || isLoadingRoles || isLoadingLines || isLoadingPlants)
  const onDelete = isEdit && handleDelete

  return (
    <div data-testid={isEdit ? 'edit-user-page' : 'create-user-page'} className={classes.root}>
      {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
        <CreateEditWrapper
          onBack={() => !!(history.location.key) ? history.goBack() : history.push('/users')}
          buttonTitle={isEdit ? "Save" : "Create"}
          pageTitle={isEdit ? "Edit User" : "Create User"}
          submit={submit}
          isLoading={isLoading}
          isDeleting={isDeleting}
          classes={classes}
          headerContentRight={
            onDelete &&
            <ProgressButton
              variant={PROGRESS_BUTTON_VARIANTS.STOP}
              disable={isLoading}
              isLoading={isDeleting}
              color="secondary"
              text="Delete"
              className={`${classes.button}`}
              onClick={onDelete}
              data-testid="CreateEditDelete"
            />
          }
        >
            <div className={classes.content}>
              <Typography variant="h5" paragraph>User Information</Typography>
              <div className={classes.userInfo}>

              <TextField
                value={user.emailAddress}
                onChange={(e) => {updateFormData({emailAddress: e.target.value})}}
                onBlur={e => {handleSearch(e.target.value)}}
                required
                label="Email Address"
                disabled={isLoading || isEdit}
                className={classes.formControl} />

              <SSTDropdown
                classes={classes}
                error={timeZoneError}
                disabled={isLoading || !!existingUser}
                required={!existingUser}
                isLoadingContents={false}
                label={"Time Zone"}
                selectedValueId={user.tz || ""}
                setValueFunc={(e) => {updateFormData({tz: e.target.value})}}
                mappedList={timezones.map(zone => <MenuItem key={zone.id}
                                                            value={zone.id}>{zone.formattedName}</MenuItem>)} />

              <TextField
                value={user.firstName}
                onChange={(e) => {updateFormData({firstName: e.target.value})}}
                label="First name"
                fullWidth
                required
                disabled={isLoading || !!existingUser}
                className={classes.formControl} />

              <TextField
                value={user.lastName}
                onChange={(e) => {updateFormData({lastName: e.target.value})}}
                required
                label="Last name"
                fullWidth
                disabled={isLoading || !!existingUser}
                className={classes.formControl} />

              <br/>

                {!isEdit &&
                  <TextField
                    type='password'
                    label={"Password"}
                    value={user.password}
                    autoComplete="new-password"
                    fullWidth
                    required
                    onChange={(e) => {updateFormData({password: e.target.value})}}
                    disabled={isLoading || !!existingUser}
                    error={passwordError}
                    helperText={LOCAL_STRINGS.PASSWORD_RULES}
                    className={classes.formControl} />
                }
                {!isEdit &&
                  <TextField
                    type='password'
                    label={"Confirm Password"}
                    autoComplete="new-password"
                    value={confirmPassword}
                    fullWidth
                    required
                    onChange={(e) => {setConfirmPassword(e.target.value)}}
                    disabled={isLoading || !!existingUser}
                    error={passwordError}
                    className={classes.formControl} />
                }

              {existingUser && <Typography className={classes.alreadyExists}>This user already exists on the platform. Click create to add them to this customer</Typography>}
              </div>

              {!isLoadingRoleStuff && isEdit && userRoles && roles &&
                <>
                  <div className={classes.divider}/>
                  <div className={classes.roleManager}>
                    <RolesManager
                      userRoles={userRoles}
                      roles={roles}
                      plants={plants}
                      lines={lines}
                      customerId={user.customerId}
                      onUpdate={handleRoleUpdate} />
                  </div>
                </>}
            </div>
        </CreateEditWrapper>
      }
    </div>);
};

export default CreateAndEditUser;
