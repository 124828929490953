import { makeStyles } from '@mui/styles'

const MOBILE_VIEW_THRESHOLD = 'lg' // https://mui.com/material-ui/customization/breakpoints/

const useStyles = makeStyles(theme => ({

    root: {
        display: 'flex',
        flexDirection: 'row',
        flex: 'auto',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundImage: 'linear-gradient(-135deg, #2c356d 10%, #2438a3 90%)',

        [theme.breakpoints.down(MOBILE_VIEW_THRESHOLD)]: {
            height: 'unset',
            minHeight: '100vh',
            flexDirection: 'column',
            flexWrap: 'nowrap',
        }
    },
    logoContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
    },
    logo: {
        display: 'block',
        objectFit: 'scale-down',
        maxWidth: 873,
        maxHeight: 187,
        width: '80%',
        height: '18%',
        alignSelf: 'center',

        [theme.breakpoints.down(MOBILE_VIEW_THRESHOLD)]: {
            width: '100%',
            height: '100%',
            padding: theme.spacing(4)
        }
    },
    contentContainer: {
        display: 'flex',
        height: '100%',
        width: '40%',
        flexDirection: 'column',
        background: 'white',

        [theme.breakpoints.down(MOBILE_VIEW_THRESHOLD)]: {
            width: '100%',
        }
    },
    innerContentContainer: {
        display: 'flex',
        flexGrow: 1,
        overflow: 'auto',

        [theme.breakpoints.down(MOBILE_VIEW_THRESHOLD)]: {
            overflow: 'unset',
        }
    },
    footerContainer: {
        padding: 32
    }
}));

export default useStyles