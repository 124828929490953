import React, {useContext, useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useMutation, useQuery} from 'react-query';
import {SiteContext} from "../../Context";
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ProtectedMoment from '../../shared/components/ProtectedMoment/ProtectedMoment';
import {DATE_TIME_TIMEZONE_FORMAT, formatBytes, handlePermissionRedirect, PERMISSION_METHOD_GET} from '../../shared/Utilities';
import ProgressWrapper from '../../shared/components/ProgressWrapper';
import ProgressButton from "../../shared/components/ProgressButton/ProgressButton";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import translations from '../../translations/en.json';

import {
  getErrorReport,
  downloadErrorReport,
  getQuantifeelSoftwareRelease,
  getQuantifeelSoftware,
} from '../../query/queries'

const pageTitle = 'View Error Report';
const acceptablePagePermission = [
  {entity: 'ErrorReport', method: PERMISSION_METHOD_GET, modifier: ''}
]

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    width: '100%',
    overflowY: 'auto'
  },
  content: {
    display: 'flex',
    margin: '20px 30px 10px',
    borderBottom: '1px solid #615E5E',

    '&>*': {
      flexGrow: '1'
    }
  },
  buttonContainer: {
    margin: '20px 30px'
  },
  title: {
    marginBottom: '20px'
  },
  linearLoad: {
    margin:'10px 0 26px 0',
    width: '250px'
  }
})

const ErrorReportDetails = () => {
  const {params} = useRouteMatch();
  const history = useHistory();
  const {setBreadcrumbs, userDetails, hasPermission} = useContext(SiteContext)

  const {isLoading: isLoadingErrorReport, data:errorReport={}} = useQuery(['errorReport', {errorReportId: params.errorReportId}], getErrorReport);

  const {isLoading: isLoadingQuantifeelRelease, data:quantifeelSoftwareRelease} =
    useQuery(
      ['quantifeelSoftwareRelease', errorReport],
      () => getQuantifeelSoftwareRelease(errorReport?.softwareId, errorReport?.softwareReleaseId),
        {enabled: (!!errorReport?.softwareId || !!errorReport?.softwareReleaseId)}
    );

  const {isLoading: isLoadingQuantifeelSoftware, data:quantifeelSoftware} =
    useQuery(
      ['quantifeelSoftware', errorReport],
      () => getQuantifeelSoftware(errorReport?.softwareId),
        {enabled: !!(errorReport?.softwareId)}
    );

  const {mutate:mutateDownload, isLoading: isDownloading} = useMutation(downloadErrorReport, {
    onSuccess: (data) => {
      const link = document.createElement('a');
      link.href = data.url;
      link.setAttribute('download', 'Error Report.zip');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onError: ({response: {data}}) => {
      console.log(data);
    }
  });
  
  const classes = useStyles();

  useEffect(() => {
    document.title = "View Error Report";
    setBreadcrumbs([{ title: "View Error Report" }]);
  }, [setBreadcrumbs])

  const handleDownload = ()=>{
    mutateDownload(params.errorReportId)
  }

  const softwareName = quantifeelSoftware?.name || translations.common.unknown
  const softwareReleaseName = quantifeelSoftwareRelease?.version || translations.common.unknown;

  return (
    <div className={classes.root} data-testid="view-error-report-page">
      {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
        <div>
          <PageHeader pageTitle="Error Report" onBack={() => !!(history.location.key) ? history.goBack() : history.push('/list-error-reports')}/>
          <div className={classes.content}>
            <div className={classes.column}>
              <Typography variant="h5">Submitted Company</Typography>
              <ProgressWrapper linear isLoading={isLoadingErrorReport} linearClass={classes.linearLoad}>
                <Typography paragraph>{errorReport.submittedCompany}</Typography>
              </ProgressWrapper>

              <Typography variant="h5">Submitted Name</Typography>
              <ProgressWrapper linear isLoading={isLoadingErrorReport} linearClass={classes.linearLoad}>
                <Typography paragraph>{errorReport.submittedName}</Typography>
              </ProgressWrapper>

              <Typography variant="h5">Submitted Email</Typography>
              <ProgressWrapper linear isLoading={isLoadingErrorReport} linearClass={classes.linearLoad}>
                <Typography paragraph>{errorReport.submittedEmail}</Typography>
              </ProgressWrapper>

              <Typography variant="h5">License Name</Typography>
              <ProgressWrapper linear isLoading={isLoadingErrorReport} linearClass={classes.linearLoad}>
                { errorReport.licenseName ?
                  <Typography paragraph>{errorReport.licenseName}</Typography> :
                  <Typography paragraph sx={{ fontStyle: 'italic' }}>Not Specified</Typography>
                }
              </ProgressWrapper>
            </div>
            <div className={classes.column}>
              <Typography variant="h5">Upload Size</Typography>
              <ProgressWrapper linear isLoading={isLoadingErrorReport} linearClass={classes.linearLoad}>
                <Typography paragraph>{formatBytes(errorReport.size)}</Typography>
              </ProgressWrapper>

              <Typography variant="h5">Details</Typography>
              <ProgressWrapper linear isLoading={isLoadingErrorReport} linearClass={classes.linearLoad}>
                <Typography paragraph>{errorReport.submittedDetails || '-'}</Typography>
              </ProgressWrapper>

              <Typography variant="h5">Date Created</Typography>
              <ProgressWrapper linear isLoading={isLoadingErrorReport} linearClass={classes.linearLoad}>
                <Typography paragraph><ProtectedMoment date={errorReport.dateCreated} format={DATE_TIME_TIMEZONE_FORMAT} tz={userDetails.user.tz}></ProtectedMoment></Typography>
              </ProgressWrapper>

              <Typography variant="h5">Software</Typography>
              <ProgressWrapper linear isLoading={isLoadingErrorReport || isLoadingQuantifeelSoftware} linearClass={classes.linearLoad}>
                <Typography paragraph>{softwareName}</Typography>
              </ProgressWrapper>

              <Typography variant="h5">Software Version</Typography>
              <ProgressWrapper linear isLoading={isLoadingErrorReport || isLoadingQuantifeelRelease} linearClass={classes.linearLoad}>
                <Typography paragraph>{softwareReleaseName}</Typography>
              </ProgressWrapper>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <ProgressButton isLoading={isDownloading} onClick={handleDownload} text={'Download Package'} />
          </div>
        </div>
      }
    </div>
  )
}

export default ErrorReportDetails