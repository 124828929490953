import { makeStyles } from '@mui/styles'
import { PROGRESS_BUTTON_VARIANTS } from './ProgressButton'
import * as GreyScale from '@Theme/GreyScale'

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: 128,
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none'
    },

    '&:disabled': {
      opacity: 0.5,
    },

    '& > p': {
      fontSize: `20px`,
      fontWeight: `500`,
      lineHeight: `24px`,
      textTransform: 'none',
    }
  },
  [PROGRESS_BUTTON_VARIANTS.PRIMARY]: {
    color: 'white',
    backgroundColor: theme.palette.darkBlue,

    '&:hover': {
      backgroundColor: theme.palette.darkerBlue,
    },

    '&:disabled': {
      color: 'white',
      backgroundColor: theme.palette.darkBlue,
    }
  },
  [PROGRESS_BUTTON_VARIANTS.SECONDARY]: {
    color: 'black',
    backgroundColor: GreyScale.GREY_2,

    '&:hover': {
      backgroundColor: GreyScale.GREY_3,
    },

    '&:disabled': {
      color: 'black',
      backgroundColor: GreyScale.GREY_2,
    }
  },
  // [VARIANTS.START]: {},
  [PROGRESS_BUTTON_VARIANTS.STOP]: {
    color: 'white',
    backgroundColor: theme.palette.red,

    '&:hover': {
      backgroundColor: theme.palette.darkRed,
    },

    '&:disabled': {
      color: 'black',
      backgroundColor: theme.palette.red,
    }
  }
}))

export default useStyles