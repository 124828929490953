import React, {useEffect} from "react";
import { Typography } from '@mui/material'
import SupportForm from '../../shared/components/SupportForm/SupportForm';
import useStyles from './Styles.js';
import {
    SST_SUPPORT_EMAIL,
    SST_SUPPORT_PHONE,
} from '../../Constants'
import translations from '../../translations/en.json';
import GiantLogoPageTemplate from '../../shared/components/GiantLogoPageTemplate/GiantLogoPageTemplate'
import QuantifeelRouterLink from '../../shared/components/QuantifeelRouterLink/QuantifeelRouterLink'
import QuantifeelLink from '../../shared/components/QuantifeelLink/QuantifeelLink'

const Support = (props) => {

    const classes = useStyles();

    useEffect(() => {
        document.title = translations.pages.support.title;
    });

    const ContentSection = ({headerText, contentComponent, additionalClassName}) => {
        const className = `${classes.contentContainer}  ${additionalClassName}`;
        return (
            <div className={className}>
                <Typography variant="h5" className={classes.contentContainerHeader}>
                    {headerText}
                </Typography>
                {contentComponent}
            </div>
        );
    }

    const KeyValue = ({keyText, valueComponent}) => {
        return (
            <div className={classes.keyValuePairContainer}>
                <Typography className={classes.keyValuePairKey}>
                    {keyText}
                </Typography>
                {valueComponent}
            </div>
        );
    }

    const strippedSstSupportPhone = SST_SUPPORT_PHONE.replace(/[\s-()]/g, ""); // Replace whitespace, dashes, and parentheses with blank string

    return (
        <GiantLogoPageTemplate showFooter={false}>

            <div className={classes.contentContainersContainer}>

                <ContentSection
                    additionalClassName={classes.formContainer}
                    headerText={translations.common.needSupport}
                    contentComponent={
                        <SupportForm/>
                    }
                />

                <ContentSection
                    headerText={translations.common.contactUs}
                    contentComponent={
                        <div>
                            <KeyValue
                                keyText={translations.common.email + ":"}
                                valueComponent={
                                    <QuantifeelLink className={classes.link} href={"mailto:" + SST_SUPPORT_EMAIL}>
                                        <Typography>{SST_SUPPORT_EMAIL}</Typography>
                                    </QuantifeelLink>
                                }
                            />
                            <KeyValue keyText={translations.common.phone + ":"}
                                valueComponent={
                                    <QuantifeelLink className={classes.link} href={"tel:" + strippedSstSupportPhone}>
                                      <Typography>{SST_SUPPORT_PHONE} {translations.pages.support.phoneOptionSuffix}</Typography>
                                    </QuantifeelLink>
                                }
                            />
                        </div>
                    }
                />

                <ContentSection
                    headerText={translations.common.privacyPolicy}
                    contentComponent={
                        <QuantifeelRouterLink to="/privacy">
                            <Typography>{translations.common.privacyPolicy}</Typography>
                        </QuantifeelRouterLink>
                    }
                />

            </div>

        </GiantLogoPageTemplate>
    );
};

export default Support;
