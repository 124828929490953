import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {BASE_SST_API_URL, LOCAL_STRINGS, SST_API_PATH_USERS_RESET_PASSWORD} from "../../Constants";
import TextField from '@mui/material/TextField';
import ProgressButton from "../../shared/components/ProgressButton/ProgressButton";
import SSTAlert from "../../shared/components/SSTAlert";
import { Typography } from '@mui/material';
import useStyles from './Styles';
import GiantLogoPageTemplate from '../../shared/components/GiantLogoPageTemplate/GiantLogoPageTemplate'

const ResetPassword = (props) => {

    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [email] = useState(new URLSearchParams(window.location.search).get('emailAddress'));
    const [passwordResetToken] = useState(new URLSearchParams(window.location.search).get('passwordResetToken'));
    const [errorMessage, setErrorMessage] = useState("");

    const classes = useStyles();

    useEffect(() => {
        document.title = "Reset Password";
    }, []);

    const updatePassword1 = e => {
        setPassword1(e.target.value);
        if (e.target.value === password2 || password2 === "") {
            setErrorMessage("");
        } else {
            setErrorMessage("Passwords mismatch!");
        }
    };
    const updatePassword2 = e => {
        setPassword2(e.target.value);
        if (password1 === e.target.value) {
            setErrorMessage("");
        } else {
            setErrorMessage("Passwords mismatch!");
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    const attemptReset = async () => {

        setIsLoading(true);
        if (password1 === password2) {
            const response = await fetch(BASE_SST_API_URL + SST_API_PATH_USERS_RESET_PASSWORD,
                {
                    method: "POST",
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify({
                        emailAddress: email,
                        newPassword: password1,
                        passwordResetToken: passwordResetToken
                    })
                });

            if (response.ok) {
                props.history.push("/login");
            } else {
                const jsonResponse = await response.json();
                setErrorMessage(jsonResponse.message);
            }
        } else {
            setErrorMessage("Passwords mismatch!");
        }
        setIsLoading(false);

    };

    const submit = e => {
        e.preventDefault();
        attemptReset();
    };

    return (
        <GiantLogoPageTemplate>

            <Form className={classes.form} onSubmit={submit}>
                <Typography variant="h2" gutterBottom>
                  Reset Password
                </Typography>
                <SSTAlert message={errorMessage}/>
                <TextField className={classes.textField} label={"Email"} value={email}
                           autoFocus
                           disabled
                           required/>
                <TextField className={classes.textField} type="password" label="New Password"
                           value={password1}
                           onChange={updatePassword1}
                           error={errorMessage !== ""}
                           required
                           helperText={LOCAL_STRINGS.PASSWORD_RULES}/>
                <TextField className={classes.textField} type="password" label="Confirm New Password"
                           value={password2}
                           onChange={updatePassword2}
                           error={errorMessage !== ""}
                           required/>
                <ProgressButton isLoading={isLoading} text={"Reset Password"} className={classes.button}/>
            </Form>

        </GiantLogoPageTemplate>
    );
};

export default ResetPassword;
