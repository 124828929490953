import React, {useContext} from 'react';
import {SvgIcon, Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import {SiteContext} from "../../../Context";

const grey1 = '#C4C4C4';
const grey2 = '#EAEAEA';

const useStyles = makeStyles({
  root: {
    '& *': {
      boxSizing: 'border-box'
    },
    display: 'inline-block',
    border: '1px solid black',
    borderRadius: '4px'
  },
  header: {
    backgroundColor: grey1,
    height: '60px',
    fontSize: '20px',
    lineHeight: '24px',
    verticalAlign: 'middle',
    padding: '18px'
  },
  icon: {
    fontSize: '46px',
    color: 'black'
  },
  children: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '20px'
  },
  child: {
    height: '200px',
    width: '200px',
    border: '1px solid black',
    borderRadius: '4px',
    backgroundColor: grey2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  link: {
    textDecoration: 'none',
    color: 'black',

    '&:hover' : {
      textDecoration: 'none'
    }
  }
});



const AdminCard = (props) => {
  const {name, children, authFn} = props;
  const {checkPermission} = useContext(SiteContext);

  const classes = useStyles();

  const cards = authFn
      ? children.filter((i) => checkPermission(i, authFn))
      : children

  if (cards.length > 0) {
    return <div className={classes.root}>
      <Typography className={classes.header}>{name}</Typography>
      <div className={classes.children}>
        {cards.map(c => (<Link to={c.link} className={classes.link} key={'admin-card-' + c.label}>
          <div className={classes.child}>          
            {c.icon && <SvgIcon component={c.icon} viewBox={c.viewBox} className={classes.icon}/>}            
            <Typography className={classes.label}>{c.label}</Typography>          
          </div>
        </Link>))}
      </div>
    </div>
  }
  else{
    return null;
  }

}


export default AdminCard