import React from "react";
import { makeStyles } from '@mui/styles';
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from '../ProgressButton/ProgressButton'
import PageHeader from '../PageHeader/PageHeader'
import translations from '../../../translations/en.json'
import PageFooter from '../PageFooter/PageFooter'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'white'
  },
  header: {
    borderBottom: '1px solid #e4e4e4',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 26px'
  },
  content: {
    flexGrow: 1,
    overflowY: 'auto'
  },
  footer: {
    backgroundColor: '#EAEAEA',
    padding: '18px 40px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    marginRight: '20px',
    '&:last-child': {
      marginRight: 0
    }
  },
  title: {
    fontSize: '20px',
    lineHeight: '24px',
    marginLeft: '16px'
  },
  backButton: {
    fontSize: '32px',
    color: '#615E5E',
    padding: '8px',
    border: '1px solid black',
    borderRadius: '3px',
    display: 'block',
    cursor: 'pointer'
  },
  divider: {
    height: '27px',
    width: '1px',
    backgroundColor: '#8F8F8F',
    marginLeft: '26px',
    marginRight: '26px'
  },
  footerContentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerContentContainerSubContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(5),
    }
  },
}))

const CreateEditWrapper = (props) => {
  const {
    pageTitle, 
    buttonTitle, 
    submit,
    isLoading, 
    children, 
    isDeleting,
    onBack,
    disableSubmit,
    headerContentRight,
  } = props;
  
  const classes = useStyles();

  const renderHeader = () => {
    return (
      <PageHeader
        onBack={onBack}
        pageTitle={pageTitle}
        contentRight={headerContentRight}
      />
    )
  }

  const renderFooter = () => {
    return (
      <PageFooter>
        <div className={classes.footerContentContainer}>
          <div className={classes.footerContentContainerSubContainer}>
            {/* Cancel */}
            <ProgressButton
              variant={PROGRESS_BUTTON_VARIANTS.SECONDARY}
              disable={isDeleting}
              isLoading={isLoading}
              text={translations.common.cancel}
              className={classes.button}
              data-testid="CreateEditCancel"
              onClick={onBack}
            />
          </div>
          <div className={classes.footerContentContainerSubContainer}>
            {/* Create / Save */}
            <ProgressButton
              variant={PROGRESS_BUTTON_VARIANTS.PRIMARY}
              disable={isDeleting || disableSubmit}
              isLoading={isLoading}
              text={buttonTitle}
              className={classes.button}
              data-testid="CreateEditSubmit"
            />
            </div>
        </div>
      </PageFooter>
    )
  }

  return (
    <form id="createEditForm" onSubmit={submit} className={classes.root} autoComplete="off">

      {renderHeader()}

      <div className={classes.content}>
          {children}
      </div>
  
      {renderFooter()}
      
    </form>
)};

export default CreateEditWrapper;