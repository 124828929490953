import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { SvgIcon, Typography, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {ReactComponent as LogoIcon} from '../../../img/icons/logo.svg';
import {ReactComponent as LogoutIcon} from '../../../img/icons/exit_to_app.svg';
import {ReactComponent as AccountIcon} from '../../../img/icons/account.svg';
import {ReactComponent as SelectIcon} from '../../../img/icons/Drop down.svg';
import ProtectedMoment from '../ProtectedMoment/ProtectedMoment';
import { isLoggedIn } from '../../Utilities'
import { SiteContext } from '../../../Context'
import * as GreyScale from '@Theme/GreyScale.js'

const useStyles = makeStyles({
  container: {
    width:'100%',
    padding: '10px 14px',
    backgroundColor: GreyScale.GREY_1,
    '&, & *': {
      boxSizing: 'border-box'
    },
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #8F8F8F'
  },
  logoImage: {
    fontSize: '32px',
    marginRight: '20px',
    marginLeft: 13,
    verticalAlign: 'middle'
  },
  logoText: {
    fontSize: '20px',
    fontWeight: '500',
    verticalAlign: 'middle',
    display: 'inline'
  },
  clock: {
    '&.MuiTypography-root': {
      fontSize: '20px',
      lineHeight: '30px'
    }
  },
  icon: {
    fontSize: '24px',
    lineHeight: '30px',
    verticalAlign: 'middle',
    marginTop: '4px',
    marginLeft: '30px',
    cursor: 'pointer',
    color: 'black',
  },
  customer: {
    minWidth: 10,
    maxWidth: 300,
    verticalAlign: 'middle',
  },
  customerSelect: {
    fontWeight: 600,
  },
  hideIcon: {
    display: 'none'
  }
})

const TopBar = (props) => {

  const [time, setTime] = useState(new Date());
  const {
    customers, 
    selectedCustomer, 
    onCustomerChange,
    logoutLink,
    profileLink,
    disableCustomerList
  } = props;

  const {userDetails} = useContext(SiteContext);

  useEffect(() => {
    const interval = setInterval(()=> {
      setTime(new Date())
    }, 1000);
    return () => {clearInterval(interval)}
  }, [])

  const handleCustomerChange = (e) => {
    if(onCustomerChange){
      onCustomerChange(customers.find(c => c.id === e.target.value));
    }
  }

  const sortFunc = (a, b) => {
    const nameA = a.name.toUpperCase(),
          nameB = b.name.toUpperCase();

    if(a.id && a.parentCustomerId && a.id === a.parentCustomerId){
      return -1;
    }
    else if (b.id && b.parentCustomerId && b.id === b.parentCustomerId){
      return 1;
    }
    else if (nameA < nameB) {
      return -1;
    }
    else if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const classes = useStyles();

  return <div className={classes.container}>
    <div>
      <SvgIcon component={LogoIcon} classes={{root: classes.logoImage}} viewBox="0 0 24 24"/>
      <p className={classes.logoText}>Quantifeel</p>
    </div>
    <div>
      <Typography display="inline" className={classes.clock}>
        <ProtectedMoment format="HH:mm" date={time}/>
      </Typography>      
    </div>
    <div>
      {customers && <TextField
        select
        variant='standard'
        value={customers.length > 0 ? selectedCustomer : ''}
        onChange={handleCustomerChange}
        className={classes.customer}
        disabled={disableCustomerList}
        InputProps={{
          disableUnderline: true,
          readOnly: customers.length === 1
        }}
        SelectProps={{
          IconComponent: SelectIcon,
          classes: {
            root: classes.customerSelect,
            icon: customers.length === 1 && classes.hideIcon
          },
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            }
          }
        }}>
        {customers.sort(sortFunc).map(c => <MenuItem value={c.id} key={'TopBar-' + c.id}>{c.id === c.parentCustomerId ? <b><i>{c.name}</i></b> : c.name}</MenuItem>)}
      </TextField> }
      <Link to={profileLink}>
        <SvgIcon component={AccountIcon} className={classes.icon} viewBox="0 0 32 32"/>
      </Link>
      { isLoggedIn(userDetails) && (
        <Link to={logoutLink}>
          <SvgIcon component={LogoutIcon} className={classes.icon} viewBox="0 0 32 32"/>
        </Link>
      )}
    </div>
  </div>
}

export default TopBar;