import { SvgIcon, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, {useContext, useEffect, useRef, useState} from 'react'
import useCollapse from 'react-collapsed'
import { Link } from 'react-router-dom'
import * as GreyScale from '@Theme/GreyScale'
import {SiteContext} from "../../../Context";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.darkBlue,
  },
  menuItem: {
    borderBottom: '1px solid #8F8F8F',
    color: 'white',
    padding: '6px 25px',
    minWidth: '224px',
    transition: '50ms linear',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: GreyScale.GREY_1,
      color: theme.palette.darkBlue,
    },
    '&.selected': {
      backgroundColor: 'white',
      color: theme.palette.darkBlue,
    },
  },
  icon: {
    fontSize: 26,
    verticalAlign: 'middle',
  },
  collapsedIcon: {
    transition: '150ms linear',
    transform: ({ collapsed, rotateOnCollapsed }) =>
      collapsed && rotateOnCollapsed ? 'rotate(180deg)' : '',
  },
  label: {
    marginLeft: '15px',
    lineHeight: '30px',
    verticalAlign: 'middle',
    opacity: ({ collapsed }) => (collapsed ? '0' : '1'),
    transition: 'opacity 150ms linear',
    display: 'inline',
    fontSize: '16px',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    color: 'white',
  },
  subMenuItems: {
    backgroundColor: theme.palette.lightBlue,
    overflowY: 'hidden',
  },
  subMenuItem: {
    fontSize: '12px',
    lineHeight: '16px',
    marginLeft: '20px',
    padding: '12px 0px',
    color: theme.palette.darkBlue,

    '&.selected': {
      fontWeight: '600',
    },
  },
}));

const SideMenuItem = (props) => {
  const [open, setOpen] = useState(false)
  const {checkPermission} = useContext(SiteContext);
  const { getCollapseProps } = useCollapse({
    isExpanded: open,
    defaultExpanded: true,
  })

  const classes = useStyles({ ...props, open })
  const { subMenuItems = [], headerLink, authFn, collapsed } = props
  const sideMenuRef = useRef(false)

  const highlightMenuItem = subMenuItems.reduce((m, i) => {
    if (!m) {
      return props.highlight?.toLowerCase() === i.label.toLowerCase()
    } else {
      return m
    }
  }, false)

  useEffect(() => {
    sideMenuRef.current = true

    if (sideMenuRef.current) {
      setOpen(!collapsed)
    }

    return () => (sideMenuRef.current = false)
  }, [collapsed])

  const items = authFn
    ? subMenuItems.filter((i) => checkPermission(i, authFn))
    : subMenuItems

  const menuItem = (
    <div className={classes.root} ref={sideMenuRef}>
      <Link to={headerLink} className={classes.link}>
        <div
          className={`${classes.menuItem} ${
            highlightMenuItem ? 'selected' : ''
          } ${
            props.highlight?.toLowerCase() === props.label.toLowerCase()
              ? 'selected'
              : ''
          }`}
        >
          {props.icon && (
            <SvgIcon
              component={props.icon}
              viewBox="0 0 32 32"
              className={`${classes.icon} ${classes.collapsedIcon}`}
            />
          )}
          <Typography
            className={classes.label + (props.collapsed ? ' collapsed' : '')}
          >
            {props.label}
          </Typography>
        </div>
      </Link>
      <div className={classes.subMenuItems} {...getCollapseProps()}>
        {items.map((m) => {
          const label = (
            <Typography
              className={`${classes.subMenuItem} ${
                props.highlight?.toLowerCase() === m.label.toLowerCase()
                  ? 'selected'
                  : ''
              }`}
            >
              {m.label}
            </Typography>
          )
          return m.link ? (
            <Link to={m.link} className={classes.link} key={'nav-' + m.label}>
              {label}
            </Link>
          ) : (
            label
          )
        })}
      </div>
    </div>
  )

  return items.length > 0 ? menuItem : null
}

export default SideMenuItem
