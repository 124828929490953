import React from 'react'
import { Typography } from '@mui/material'
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from '../../ProgressButton/ProgressButton'
import translations from '../../../../translations/en.json';
import { makeStyles } from '@mui/styles'
import QuantifeelDialog from '../QuantifeelDialog/QuantifeelDialog'

const useStyles = makeStyles(theme => ({
  dialogActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    }
  }
}));

const SaveConfirmationDialog = (props) => {

  const {
    open,
    isSaving,
    onClose,
    onCancel,
    onSave,
  } = props;

  const classes = useStyles();

  const renderTitle = () => {
    return (
      <Typography variant="h6">{translations.components.saveConfirmationDialog.title}</Typography>
    )
  }

  const renderContent = () => {
    return (
      <Typography variant="h6">{translations.components.saveConfirmationDialog.message}</Typography>
    )
  }

  const renderActions = () => {
    return (
      <div className={classes.dialogActionsContainer}>

        {/* Don't Save */}
        <ProgressButton
          disable={isSaving}
          variant={PROGRESS_BUTTON_VARIANTS.SECONDARY}
          text={translations.common.doNotSave}
          onClick={onCancel}
        />

        {/* Save */}
        <ProgressButton
          isLoading={isSaving}
          variant={PROGRESS_BUTTON_VARIANTS.PRIMARY}
          text={translations.common.save}
          onClick={onSave}
        />

      </div>
    )
  }

  return (
    <QuantifeelDialog
      open={open}
      disableCloseIcon={isSaving}
      onClose={onClose}
      title={renderTitle()}
      content={renderContent()}
      actions={renderActions()}
    />
  )
}

export default SaveConfirmationDialog