import { CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const WidgetContainer = (props) => {

    const { header, isLoading, children } = props;

    const useStyles = makeStyles(theme => ({
      root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid black',
      },
      headerContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: `0 ${theme.spacing(2)}`,
        minHeight: '36px',
        maxHeight: '36px',
        backgroundColor: theme.palette.darkBlue,
      },
      header: {
        color: 'white',
      },
      contentContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        overflowY: 'auto',
        padding: 1, // Help prevent unwanted overflow, :(
      },
      loadingIndicatorContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    }));

    const classes = useStyles();

    return (
      <div className={classes.root}>

        <div className={classes.headerContainer}>
          <Typography variant="h6" className={classes.header}>{header}</Typography>
        </div>

        <div className={classes.contentContainer}>

          {
            isLoading ?
              (
                <div className={classes.loadingIndicatorContainer}>
                  <CircularProgress/>
                </div>
              ) : (
                children
              )
          }

        </div>
      </div>
    );
};

export default WidgetContainer;
