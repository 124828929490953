import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone'
import translations from '../../../translations/en.json';


const ProtectedMoment = (props) => {
  const {date, children, toNow, ...rest} = props;
  if(children){
    return <Moment {...rest}>{children}</Moment>;
  }
  else if(date){
    return <Moment date={date} {...rest} />;
  }
  else if(toNow){
    return <Moment toNow {...rest} />
  }
  else{
    return translations.components.protectedMoment.notAvailable;
  }
}

export default ProtectedMoment;