import * as React from "react";
import {CircularProgress, Typography, Button} from '@mui/material';
import * as PropTypes from "prop-types";
import useStyles from '../ProgressButton/Styles'

const PROGRESS_BUTTON_VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  // START: "start",
  STOP: "stop",
}

const ProgressButton = (props) => {

  const {
    color='primary',
    variant,
    isLoading = false,
    leadingContent
  } = props;

  const classes = useStyles();

  return (
      <Button className={[
                (props.className || "flex-button"), // Preserve historical styles, while they're still relevant...
                variant ? `${classes.button} ${classes[variant]}` : undefined,
              ].join(" ")}
              variant="contained"
              color={color}
              type={!!(props.onClick)? "button": "submit"} // Turns given function into a boolean. Allows for a button with onClick functionlity or submit funtionality for forms.
              onClick={props.onClick}
              disabled={props.disable || isLoading}
              data-testid={props['data-testid']}>
        {
          // If loading, show loading...
          isLoading ? (
            <CircularProgress
              className="flex-progress"
              variant="indeterminate"
              disableShrink={false}
              color="inherit"
              size={23}
            />
          ) : (
            <>
              { leadingContent }
              <Typography>{props.text}</Typography>
            </>
          )
        }
      </Button>);
  };
ProgressButton.propTypes = {
    isLoading: PropTypes.bool,
    text: PropTypes.string.isRequired,
    disable: PropTypes.bool,
    onClick:PropTypes.func,
    className: PropTypes.any,
    href: PropTypes.string
};

export {PROGRESS_BUTTON_VARIANTS};
export default ProgressButton;