import {createTheme} from '@mui/material/styles';
import WorkSansTTF from '../../font/WorkSans-Regular.ttf';

import tinyColor from "tinycolor2";

export const primary = "#263238";
export const secondary = "#FF5C93";
export const danger = "#ff4444";
export const warning = "#FF8800"; //#ffbb33
export const success = "#00695c";
export const info = "#aa66cc";

const lightenRate = 7.5;
const darkenRate = 15;

// Work around for preview prs
const WorkSansTTFURL = (process.env.REACT_APP_PREVIEW === "true") ? window.location.href.replace(/\/(index\.html.*)?$/, "").concat(WorkSansTTF) : WorkSansTTF;

export default createTheme({
    typography: {
      fontFamily: 'WorkSans'
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: 'WorkSans',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${WorkSansTTFURL})`,
            unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
          },
          '@global': {
            '*::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(0,0,0,0.03)'
            },
            '*::-webkit-scrollbar-corner': {
              backgroundColor: 'rgba(0,0,0,0.03)'
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              borderRadius: '30px',
            }
          },
        }
        
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard'
        }
      }
    },
    palette: {
        primary: {
            main: primary,
            light: tinyColor(primary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinyColor(primary)
                .darken(darkenRate)
                .toHexString()
        },
        secondary: {
            main: secondary,
            light: tinyColor(secondary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinyColor(secondary)
                .darken(darkenRate)
                .toHexString(),
            contrastText: "#FFFFFF"
        },
        danger: {
            main: danger,
            light: tinyColor(danger)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinyColor(danger)
                .darken(darkenRate)
                .toHexString()
        },
        warning: {
            main: warning,
            light: tinyColor(warning)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinyColor(warning)
                .darken(darkenRate)
                .toHexString()
        },
        success: {
            main: success,
            light: tinyColor(success)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinyColor(success)
                .darken(darkenRate)
                .toHexString()
        },
        info: {
            main: info,
            light: tinyColor(info)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinyColor(info)
                .darken(darkenRate)
                .toHexString()
        },
        text: {
            primary: "#4A4A4A",
            secondary: "#6E6E6E",
            hint: "#B9B9B9"
        },
        background: {
            default: "#F6F7FF",
            light: "#F3F5FF"
        },

        // Blue
        darkBlue: '#0E4DA4',
        darkerBlue: '#09336B',
        lightBlue: '#EBF2FF',
        hyperlinkBlue: '#007bff',

        // Grey
        darkGrey: '#8F8F8F',

        // Orange
        orange: '#FF7A00',
        spinOrange: '#fba91b',

        // Red
        red: '#D02C2F',
        darkRed: '#9F1B1B',

        // Yellow
        rotationYellow: '#F5D218',
    },
    customShadows: {
        widget:
            "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
        widgetDark:
            "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
        widgetWide:
            "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
    },
    overrides: {
          MuiCssBaseline: {
            
        },
        MuiPaper: {
            root: {
                width: 'fit-content',
                display: 'flex',
                flexFlow: 'row wrap',
                alignItems: 'center',
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: "#4A4A4A1A"
            }
        },
        MuiMenu: {
            paper: {
                boxShadow:
                    "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
                backgroundColor: "#F3F5FF"
            }
        },
        MuiSelect: {
            icon: {
                color: "#B9B9B9",
            }
        },
        MuiListItem: {
            "root": {
                "&$selected": {
                    "backgroundColor": "#F3F5FF !important",
                    "&:focus": {
                        "backgroundColor": "#F3F5FF"
                    }
                }
            }
        },
        MuiTouchRipple: {
            child: {
                backgroundColor: "white"
            }
        },
        MuiTableRow: {
            root: {
                height: 56,
            }
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid rgba(224, 224, 224, .5)',
            },
            head: {
                fontSize: '0.95rem',
            },
            body: {
                fontSize: '0.95rem',
            }
        },
        MuiTypography: {
        }
    }
});