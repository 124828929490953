import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import TextField from '@mui/material/TextField';
import {BASE_SST_API_URL, SST_API_PATH_USERS_REQUEST_PASSWORD_RESET} from "../../Constants";
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from '../../shared/components/ProgressButton/ProgressButton'
import SSTAlert from "../../shared/components/SSTAlert";
import Typography from '@mui/material/Typography';
import GiantLogoPageTemplate from '../../shared/components/GiantLogoPageTemplate/GiantLogoPageTemplate'
import useStyles from './Styles'
import QuantifeelDialog from '../../shared/components/Dialogs/QuantifeelDialog/QuantifeelDialog'
import translations from '../../translations/en.json'

const ForgotPassword = (props) => {

    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        document.title = "Forgot Password";
    }, []);

    const updateEmail = e => {
        setEmail(e.target.value);
    };

    const requestReset = async () => {
        setIsLoading(true);

        const response = await fetch(BASE_SST_API_URL + SST_API_PATH_USERS_REQUEST_PASSWORD_RESET + "?emailAddress=" +  encodeURIComponent(email));

        if (response.ok) {
            setOpenDialog(true);
        } else {
            const jsonResponse = await response.json();
            setErrorMessage(jsonResponse.message);
        }
        setIsLoading(false);

    };

    const submit = e => {
        e.preventDefault();
        requestReset();
    };

    const [openDialog, setOpenDialog] = useState(false);
    const handleOnCloseDialog = e =>{
        setOpenDialog(false);
        props.history.push("/login");
    };

    const renderDialog = () => {

        const title = <Typography variant="h6">{translations.pages.forgotPassword.forgotPassword}</Typography>
        const content = <Typography variant="h6">{translations.pages.forgotPassword.resetPasswordConfirmation}</Typography>
        const actions =
          <div className={classes.actionsContainer}>
              <ProgressButton
                  variant={PROGRESS_BUTTON_VARIANTS.PRIMARY}
                  text={"Close"}
                  onClick={handleOnCloseDialog}
              />
          </div>

        return (
            <QuantifeelDialog
                open={openDialog}
                onClose={handleOnCloseDialog}
                title={title}
                content={content}
                actions={actions}
            />
        )
    }

    return (
        <GiantLogoPageTemplate>

            <Form className={classes.form} onSubmit={submit}>
                <SSTAlert message={errorMessage}/>
                <TextField
                    className={classes.textField}
                    label={translations.common.email}
                    value={email}
                    onChange={updateEmail}
                    autoFocus required
                />
                <ProgressButton
                    isLoading={isLoading}
                    text={translations.pages.forgotPassword.sendPasswordResetEmail}
                    className={classes.button}
                />
            </Form>

            {renderDialog()}

        </GiantLogoPageTemplate>
    );
};

export default ForgotPassword;
