import React from 'react';
import {Link} from 'react-router-dom';
import { SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const darkBlue = '#0E4DA4';
const lightGrey = '#EAEAEA';

const useStyles = makeStyles({
  menuItem: {
    borderBottom: '1px solid #8F8F8F',
    color: 'white',    
    padding: '6px 25px',
    width: '224px',
    transition: '50ms linear',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: lightGrey,
      color: darkBlue      
    },
    '&.selected': {
      backgroundColor: 'white',
      color: darkBlue
    }
  },
  icon: {
    fontSize: 26,
    verticalAlign: 'middle',
    transition: '150ms linear',
    transform: ({collapsed, rotateOnCollapsed}) => collapsed && rotateOnCollapsed ? 'rotate(180deg)' : ''
  },
  label: {
    marginLeft: '15px',
    lineHeight: '30px',
    verticalAlign: 'middle',
    opacity: ({collapsed}) => collapsed ? '0' : '1',
    transition: 'opacity 150ms linear',
    display: 'inline',
    fontSize: '16px'
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  }
});

const SideMenuItem = (props) => {

  const classes = useStyles(props);
  
  const handleClick = () => {
    if(props.onClick){
      props.onClick();
    }
  }

  const menuItem = <div className={`${classes.menuItem} ${props.highlight?.toLowerCase() === props.label.toLowerCase() ? 'selected': ''}`} onClick={handleClick}>
            {props.icon && <SvgIcon component={props.icon} viewBox="0 0 32 32" className={classes.icon}/>}
            <Typography className={classes.label + (props.collapsed ? ' collapsed' : '')}>
              {props.label}
            </Typography>
          </div>       


  return props.link ? <Link to={props.link} className={classes.link} target={props.openInNewTab ? "_blank" : ""} >{menuItem}</Link> : menuItem;
}

export default SideMenuItem;