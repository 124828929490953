import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({

    form: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-end',
        backgroundColor: 'white',
    },
    textField: {
        paddingBottom: 20,
        width: '80%',
    },
    button: {
        width: '80%',
        margin: 8,
        borderRadius: 20,
    }
}));

export default useStyles