import React, {useContext, useEffect, useState} from "react";
import {useSnackbar} from 'notistack';
import {useHistory, useLocation} from "react-router-dom"
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {SiteContext} from "../../Context";
import queryString from "query-string";
import SSTDropdown from "../../shared/components/SSTDropdown";
import CreateEditWrapper from "../../shared/components/CreateEdit/CreateEditWrapper";
import {MenuItem, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {
    createPlant,
    deletePlant,
    getCustomer,
    getEntityPermissions,
    getPlant,
    getTimezones,
    updatePlant
} from '../../query/queries'
import { handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_INSERT } from "../../shared/Utilities";
import QuantifeelSwitch from "../../shared/components/QuantifeelSwitch";

const acceptablePagePermission = [
  {entity: 'Plant', method: PERMISSION_METHOD_INSERT, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const emptyPlant = {
  name: "",
  customerId: "",
  timezone: ""
}

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  content: {
    width: '500px',
    margin: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  formControl: {
    marginBottom: '30px',
    fontSize: '20px'
  }
})

const CreateAndEditPlant = (props) => {

    const {setBreadcrumbs, currentCustomer ,hasPermission} = useContext(SiteContext);
    const history = useHistory();
    const location = useLocation();
    const queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();

    const [isEdit, setIsEdit] = useState(false);

    const [plant, setPlant] = useState({...emptyPlant, customerId: currentCustomer});

    const [customerError, setCustomerError] = useState(false);
    
    const {isLoading: isLoadingCustomer, data: customer={}} = useQuery(['customer', {customerId: currentCustomer}], getCustomer);
    const {isLoading: isLoadingTimezones, data: timezones=[]} = useQuery(['timezones'], getTimezones);

    const {isLoading: isLoadingPlant, data: plantData} = useQuery(['plant', {plantId: plant.id}], getPlant, {
      enabled: !!plant.id
    });

    const {isLoading: isLoadingPermissions, data: permissions} = useQuery(['permissions', currentCustomer, 'plant', {ids: [plant.id]}], getEntityPermissions, {
      enabled: !!plant.id
    });

    const readOnly = isEdit ? !permissions?.plant[plant.id]?.update : false;
    const pageTitle = history.location.pathname === '/create-plant' ? 'Create Plant' : 'Edit Plant';

    useEffect(()=>{
      if(plantData){
        setPlant(plantData);
      }      
    }, [plantData]);

    const {mutate: mutateUpdate, isLoading: isUpdating} = useMutation(updatePlant, {
      onSuccess: (data) => {
        enqueueSnackbar('Plant Updated', {variant: 'success'});
        queryClient.setQueryData(['plant', {plantId: data.id}], data);
        queryClient.removeQueries('plants');
      },
      onError: ({response:{data}}) => {
        enqueueSnackbar(data.message, {variant: 'error'});
      }
    })

    const {mutate: mutateDelete, isLoading: isDeleting} = useMutation(deletePlant, {
      onSuccess: () => {
        enqueueSnackbar('Plant Deleted', {variant: 'success'});
        queryClient.removeQueries('plants');
        queryClient.removeQueries(['plant', {plantId: plant.id}]);
        history.replace('/list-plants')
      },
      onError: ({response: {data}}) => {
        enqueueSnackbar(data.message, {variant: 'error'});
      }
    })
    const {mutate: mutateCreate, isLoading: isCreating} = useMutation(createPlant, {
      onSuccess: (data) => {
        enqueueSnackbar('Plant Created', {variant: 'success'});
        queryClient.setQueryData(['plant', {plantId: data.id}], data);
        queryClient.removeQueries('plants');

        const qs = queryString.parse(props.location.search);
        const previousRoute = location.state.from
        if (previousRoute === '/create-line') {
            if(!!(qs.lineName)) {
                history.push(`/create-line?plantId=${data.id}&lineName=${qs.lineName}`);
            } else {
                history.push(`/create-line?plantId=${data.id}`);
            }
        } else {
            history.replace('/list-plants');
        }
      },
      onError: ({response: {data}}) => {
        enqueueSnackbar(data.message, {variant: 'error'});
      }
    })

    useEffect(() => {
      const qs = queryString.parse(props.location.search);

      if (qs.plantId) {
          document.title = "Edit Plant";
          setIsEdit(true);
          setPlant(p => {return {...p, id: qs.plantId}});

          setBreadcrumbs([{title: "Edit Plant"}]);
      } else {
          document.title = "Create Plant";
          setIsEdit(false);
          setBreadcrumbs([{title: "Create Plant"}]);
      }
        
    }, [setBreadcrumbs, props.location.search]);

    useEffect(() => {
      if(isEdit && currentCustomer !== plant.customerId){
        history.push('/list-plants')
      }
      else{
        setPlant(p => ({...p, customerId: currentCustomer}))
      }
    }, [currentCustomer, plant.customerId, history, isEdit])

    const submit = (e) => {
        e.preventDefault();
        if (plant.customerId !== "") {
          if(isEdit){
            mutateUpdate(plant);
          }
          else{
            mutateCreate(plant);
          }          
        }
        else{
          setCustomerError(!!plant.customerId)
        }
    };

    const updatePlantName = e => {      
      const name = e.target.value;
      setPlant({...plant, name});
    };

    const updateTimezone = e => {
      const timezone = e.target.value;
      setPlant({...plant, timezone});
    }
    const handleDelete = (e) => {
      e.preventDefault();
      if (window.confirm(`Are you sure you want to delete the plant "${plant.name}"`)) {
        mutateDelete(plant);  
      }          
    }

    const handleUpdate = (update) => {
        setPlant(plant => ({...plant, ...update}));
    }

    const onDelete = isEdit && handleDelete;

    const classes = useStyles();

    return (
        <div data-testid={isEdit ? "edit-plant-page" : "create-plant-page"} className={classes.root}>
          {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
            <CreateEditWrapper
                onBack={() => !!(history.location.key) ? history.goBack() : history.push('/list-plants')}
                buttonTitle={isEdit ? "Save" : "Create"}
                pageTitle={isEdit ? "Edit Plant" : "Create Plant"}
                submit={submit}
                isLoading={isLoadingPlant || isLoadingCustomer || isLoadingTimezones || isCreating || isUpdating || isLoadingPermissions}
                isDeleting={isDeleting}
                disableSubmit={readOnly}
                headerContentRight={
                    <QuantifeelSwitch
                        checked={!plant.inactive}
                        onChange={(e) => {handleUpdate({inactive: !e.target.checked})}}
                    />
                }
            >
                <div className={classes.content}>
                    <span className={classes.formItem}>
                      <SSTDropdown
                        classes={classes}
                        disabled={true}
                        readOnly={readOnly}
                        required={false}
                        error={customerError}
                        isLoadingContents={isLoadingCustomer}
                        label={"Customer"}
                        selectedValueId={isLoadingCustomer ? '' : currentCustomer}
                        setValueFunc={() => {}}
                        mappedList={[customer].map((customer) => <MenuItem key={customer.id}
                                                                        value={customer.id}>{customer.name}</MenuItem>)}/>
                    </span>
                    <TextField 
                      className={classes.formControl}
                      value={plant.name}
                      onChange={updatePlantName}
                      required
                      id="plantName"
                      name="plantName"
                      label="Plant Name"
                      fullWidth
                      InputProps={{
                        readOnly: readOnly
                      }}
                      disabled={isLoadingPlant || isCreating || isUpdating}/>
                    
                    <span className={classes.formItem}>
                      <SSTDropdown
                        disabled={isLoadingCustomer || isLoadingTimezones || isCreating || isUpdating}
                        readOnly={readOnly}
                        error={false}
                        isLoadingContents={isLoadingTimezones}
                        label={"Timezone"}
                        selectedValueId={isLoadingTimezones ? '' : plant.timezone}
                        setValueFunc={updateTimezone}
                        mappedList={timezones.map(zone => <MenuItem key={zone.id}
                                                                    value={zone.id}>{zone.formattedName}</MenuItem>)}/>
                    </span>
                </div>
            </CreateEditWrapper>
          }
        </div>);
};

export default CreateAndEditPlant;