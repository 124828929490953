import React, { useState } from 'react'
import { TextareaAutosize, TextField, Typography } from '@mui/material'
import Form from 'react-bootstrap/Form'
import SSTAlert from '../SSTAlert'
import ProgressButton from '../ProgressButton/ProgressButton'
import useStyles from './Styles.js';
import StatusVariant from '../StatusVariant'
import translations from '../../../translations/en.json';
import { useMutation } from 'react-query'
import { createSupportRequest } from '../../../query/entities/support'

const SupportForm = (props) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const classes = useStyles();

    const {mutate: mutateCreateSupportRequest, isLoading: isCreatingSupportRequest} = useMutation(createSupportRequest, {
        onSuccess: (data) => {
            setSuccessMessage(translations.components.supportForm.requestSuccess);
            resetFields();
        },
        onError: ({response: {data}}) => {
            setErrorMessage(data.message);
        }
    });

    const resetFields = () => {
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
    }

    const resetStatusMessages = () => {
        setSuccessMessage("");
        setErrorMessage("");
    }

    const buildSupportRequestPayload = () => {
        return {
            "name": name,
            "email": email,
            "subject": subject,
            "message": message,
        }
    }

    const submit = e => {
        e.preventDefault();
        resetStatusMessages();
        mutateCreateSupportRequest(buildSupportRequestPayload());
    };

    return (
        <div className={classes.supportFormContainer}>

            <Typography className={classes.supportFormText}>
                {translations.components.supportForm.description}
            </Typography>

            <Form className={classes.supportForm} onSubmit={submit}>

                <div className={classes.supportFormMessagesContainer}>
                    <SSTAlert variant={StatusVariant.DANGER} message={errorMessage}/>
                    <SSTAlert variant={StatusVariant.SUCCESS} message={successMessage}/>
                </div>

                <TextField
                    className={classes.supportFormField}
                    label={translations.components.supportForm.fullName}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    inputProps={{ maxLength: 256 }}
                    required
                />
                <TextField
                    className={classes.supportFormField}
                    label={translations.common.email}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                />
                <TextField
                    className={classes.supportFormField}
                    label={translations.components.supportForm.subject}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    fullWidth
                    inputProps={{ maxLength: 256 }}
                    required
                />
                <TextareaAutosize
                    className={`${classes.supportFormField} ${classes.textArea}`}
                    // Define style props as a means to OVERRIDE other component styles,
                    // since this cannot be done via makeStyles() in Styles.js...
                    style={{
                        height: '100%',
                        overflow: 'auto',
                        resize: 'none'
                    }}
                    placeholder={translations.components.supportForm.messagePlaceholder}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    required
                />

                <ProgressButton
                    isLoading={isCreatingSupportRequest}
                    text={translations.components.supportForm.submit}
                    className={classes.supportFormSubmitButton}
                />

            </Form>

        </div>
    )
}

export default SupportForm;