import React from "react";
import useStyles from './Styles.js';
import Logo from '../../../img/quantifeel_logo.png'
import Footer from '../Footer/Footer'

const GiantLogoPageTemplate = (props) => {

    const {children, showFooter = true } = props;

    const classes = useStyles();

    return (
        <div className={classes.root} data-testid="login-page">

            <div className={classes.logoContainer}>
                <img className={classes.logo} src={Logo} alt={'logo'} />
            </div>

            <div className={classes.contentContainer}>

                <div className={classes.innerContentContainer}>
                    {children}
                </div>

                {showFooter &&
                  <div className={classes.footerContainer}>
                    <Footer useUpdatedFooter={false}/>
                  </div>
                }
            </div>

        </div>
    );
};

export default GiantLogoPageTemplate;