import * as React from "react";
import useStyles from './Styles.js';
import PageHeader from '../../../shared/components/PageHeader/PageHeader'
import { useRouteMatch } from 'react-router-dom'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { FormControlLabel, RadioGroup, Tab, Typography } from '@mui/material'
import PageFooter from '../../../shared/components/PageFooter/PageFooter'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from '../../../shared/components/ProgressButton/ProgressButton'
import QuantifeelRadio from '../../../shared/components/QuantifeelRadio'
import { makeStyles } from '@mui/styles'
import NumberAdjuster from '../../../shared/components/NumberAdjuster/NumberAdjuster'
import FullScreenCircularProgress from '../../../shared/components/FullScreenCircularProgress'
import SaveConfirmationDialog from '../../../shared/components/Dialogs/SaveConfirmationDialog/SaveConfirmationDialog'
import { useSnackbar } from 'notistack'
import { SiteContext } from '../../../Context'
import translations from '../../../translations/en.json';
import {
  deepEqual,
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
} from '../../../shared/Utilities'
import {
  getLine,
  getLineSettings,
  getActiveLineSettings,
  updateLineSettings as saveLineSettings,
  getLineSettingsDefaultsAndOptions,
  getEntityPermissions,
} from '../../../query/queries'
import {
  LINE_SETTINGS_KEYS,
  LINE_SETTINGS_KEY_TO_LABEL_MAP,
  TABS,
  convertPsiToBar,
  round,
  updateLineSettings,
  getLineSettingsDefaults,
} from './Helpers'
import { SST_PAGE_LIST_LINES } from '../../../Constants'
import ReadOnlyNotification from '../../../shared/components/ReadOnlyNotification'

const pageTitle = translations.pages.lineSettings.lineSettings;

const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_GET, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const NO_ACTIVE_LINE_SETTINGS_HTTP_RESPONSE_CODE = 404;

const LineSettings = (props) => {

  const {
    history
  } = props;

  const {currentCustomer, hasPermission} = useContext(SiteContext);

  const match = useRouteMatch();
  const {enqueueSnackbar} = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOnMountApis, setIsLoadingOnMountApis] = useState(false);
  const [lineId, setLineId] = useState(null);
  const [selectedTab, setSelectedTab] = useState(TABS.ONE);
  const [isSaveConfirmationDialogOpen, setIsSaveConfirmationDialogOpen] = React.useState(false);

  // Line Settings...
  const [workingLineSettingsVersionId, setWorkingLineSettingsVersionId] = useState(null);
  const [activeLineSettings, setActiveLineSettings] = useState(null);
  const [initialActiveLineSettings, setInitialActiveLineSettings] = useState(null);
  const [lineSettingsDefaultsAndOptions, setLineSettingsDefaultsAndOptions] = useState(null);

  // --------------------------------------------
  // -- BEGIN useQuery / useMutation / useMemo --
  // --------------------------------------------

  /**
   * Given lineId,
   * Query for current customer entity permissions...
   */
  const {isLoading: isLoadingCustomerEntityPermissions, data: customerEntityPermissions} = useQuery(
    ['entityPermissions', currentCustomer, 'line', {ids: [lineId]}],
    getEntityPermissions,
    { enabled: !!lineId }
  );

  /**
   * Given lineId,
   * Query for line...
   */
  const {isLoading: isLoadingLine, data: line= {} } = useQuery(
    ['line', {lineId: lineId}],
    getLine,
    {enabled: !!lineId}
  );

  /**
   * Given lineId,
   * Query for all associated line settings...
   */
  const {isLoading: isLoadingAllAssociatedLineSettings, data: allAssociatedLineSettings= [] } = useQuery(
    ['lineSettings', {lineId: lineId}],
    getLineSettings,
    {enabled: !!lineId}
  );

  /**
   * Mutator for updating line settings, given line settings...
   */
  const {mutate: mutateUpdateLineSettings, isLoading: isUpdatingLineSettings} = useMutation(saveLineSettings, {
    onSuccess: (data, variables) => {

      // Close save confirmation dialog...
      setIsSaveConfirmationDialogOpen(false);

      // Show success...
      enqueueSnackbar(translations.pages.lineSettings.lineSettingsUpdated, {variant: 'success'});

      // Access params...
      const { lineSettings } = variables;

      // Set workingLineSettingsVersionId...
      setWorkingLineSettingsVersionId(data.versionId);

      // Set initialActiveLineSettings to line settings that were included in the payload,
      // so that we can detect changes from most recently submitted set of line settings...
      setInitialActiveLineSettings(lineSettings.settings);
    },
    onError: ({response: {data}}) => {

      // Close save confirmation dialog...
      setIsSaveConfirmationDialogOpen(false);

      // Show error...
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  });

  const isUserPermittedToUpdateLine = useMemo(() => {
    return customerEntityPermissions?.line[lineId]?.update;
  }, [customerEntityPermissions, lineId])

  const isInitialLineSettingSet = useMemo(() => {
    return allAssociatedLineSettings.length !== 0;
  }, [allAssociatedLineSettings])

  /**
   * Given changes to lineId, permissions
   * Set isReadOnly...
   */
  const isReadOnly = useMemo(() => {
    return (
      !isUserPermittedToUpdateLine ||
      !isInitialLineSettingSet // If line has no associated line settings, then isReadOnly, since we don't want to allow user to submit initial version of line settings...
    )
  }, [isUserPermittedToUpdateLine, isInitialLineSettingSet])

  /**
   * Given changes to activeLineSettings, initialLineSettings
   * Determine if line settings are dirty...
   */
  const isLineSettingsDirty = useMemo(() => {
    // If not deepEqual, then dirty...
    return !deepEqual(activeLineSettings, initialActiveLineSettings);
  }, [activeLineSettings, initialActiveLineSettings]);

  // ------------------------------
  // -- BEGIN useQuery / useMemo --
  // ------------------------------

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  /**
   * On component mount,
   * Set page title, lineId, and call getActiveLineSettings, getLineSettingsDefaultsAndOptions APIs...
   */
  useEffect(() => {

    // Validate permissions...
    handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission)

    // Set page title..
    document.title = pageTitle;

    // Set lineId...
    const lineId = match.params.lineId;
    setLineId(lineId);

    // ------------------------------
    // -- BEGIN Call APIs On Mount --
    // ------------------------------

    const commonGetActiveLineSettingsHandler = ({activeVersionId, activeLineSettings, isPharma}) => {

      // Get lineSettingsOptions...
      getLineSettingsDefaultsAndOptions({ queryKey: ["lineSettingsDefaultsAndOptions", {isPharma: isPharma}] })

        .then((lineSettingsDefaultsAndOptions) => {

          // Set workingLineSettingsVersionId...
          const localWorkingLineSettingsVersionId = activeVersionId !== null ? activeVersionId : 0;
          setWorkingLineSettingsVersionId(localWorkingLineSettingsVersionId);

          // activeLineSettings will be set to activeLineSettings, if not null, else, set to defaults from lineSettingsDefaultsAndOptions...
          const localActiveLineSetting = activeLineSettings || getLineSettingsDefaults(lineSettingsDefaultsAndOptions);

          // Set activeLineSettings...
          setActiveLineSettings(localActiveLineSetting);

          // Set initialActiveLineSettings...
          setInitialActiveLineSettings(localActiveLineSetting);

          // Set lineSettingsDefaultsAndOptions...
          setLineSettingsDefaultsAndOptions(lineSettingsDefaultsAndOptions);
        })
    }

    // Update onMountApis loading tracker...
    setIsLoadingOnMountApis(true);

    // Get activeLineSettings...
    getActiveLineSettings({ queryKey: ["activeLineSettings", {lineId: lineId}] })
      .then((response) => {

        // The given line has active line settings, therefore...
        const activeVersionId = response.versionId; // Get activeVersionId from response...
        const isPharma = !!response.settings[LINE_SETTINGS_KEYS.IS_VIAL_MODE.key]; // Get isPharma from response.settings...

        commonGetActiveLineSettingsHandler({
          activeVersionId: activeVersionId,
          activeLineSettings: response.settings,
          isPharma: isPharma
        });
      })
      .catch((error) => {

        if (error.response.status === NO_ACTIVE_LINE_SETTINGS_HTTP_RESPONSE_CODE) {

          // The given line DOES NOT HAVE active line settings, since NO_ACTIVE_LINE_SETTINGS_HTTP_RESPONSE_CODE....

          commonGetActiveLineSettingsHandler({
            activeVersionId: null,
            activeLineSettings: null,
            isPharma: false // If no active line settings, default isPharma to false...
          });
        }
      })
      .finally(() => {

        // Update onMountApis loading tracker...
        setIsLoadingOnMountApis(false);
      })

    // ----------------------------
    // -- END Call APIs On Mount --
    // ----------------------------

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to useQuery loading states...
   * Set isLoading...
   */
  useEffect(() => {
    if ( isLoadingLine ||
         isLoadingCustomerEntityPermissions ||
         isLoadingAllAssociatedLineSettings ||
         isLoadingOnMountApis ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingLine, isLoadingCustomerEntityPermissions, isLoadingAllAssociatedLineSettings, isLoadingOnMountApis]);

  // --------------------
  // -- END useEffects --
  // --------------------

  // --------------------------
  // -- BEGIN event handlers --
  // --------------------------

  const onBack = () => {
    // If there is a page in history, go back, else, go to /list-lines...
    return !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_LIST_LINES}`)
  }

  const onTabClick = (event, newValue) => {
    setSelectedTab(newValue);
  }

  const onSave = () => {
    // PUT activeLineSettings...
    mutateUpdateLineSettings({
      lineId: lineId,
      lineSettings: {
        versionId: workingLineSettingsVersionId,
        settings: activeLineSettings
      }
    });
  }

  // ------------------------
  // -- END event handlers --
  // ------------------------

  // -------------------
  // -- BEGIN renders --
  // -------------------

  const classes = useStyles();

  const renderHeader = () => {
    return (
      <PageHeader
        onBack={onBack}
        pageTitle={`${line.name} ${translations.pages.lineSettings.settings}`}
      />
    )
  }

  const renderTab = (value, label) => {
    return (
      <Tab
        value={value}
        label={label}
        className={[
          classes.tab,
          selectedTab === value ? classes.selectedTab : undefined
        ].join(' ')}
      />
    )
  }

  const renderTabPanel = (value, children) => {
    return (
      <TabPanel value={value} className={classes.tabPanel}>
        { !isUserPermittedToUpdateLine && renderReadOnlyNotification_givenInsufficientPermissions() } {/* Show insufficient permissions notification if user is not permitted to update line settings */}
        { isUserPermittedToUpdateLine  && !isInitialLineSettingSet && renderReadOnlyNotification_givenLackOfLineSettings() } {/* Show no settings defined notification if user is permitted to update line settings and line has no settings */}
        {children}
      </TabPanel>
    )
  }

  const renderReadOnlyNotification_givenLackOfLineSettings = () => {
    return (
      <div className={classes.readOnlyNotificationContainer}>
        <ReadOnlyNotification message={translations.pages.lineSettings.noLineSettingsDefined}/>
      </div>
    )
  }

  const renderReadOnlyNotification_givenInsufficientPermissions = () => {
    return (
      <div className={classes.readOnlyNotificationContainer}>
        <ReadOnlyNotification message={translations.common.insufficientPermissionsToUpdateSettings}/>
      </div>
    )
  }

  const SettingCard = ({title, children}) => {
    return (
      <div className={classes.settingCard}>
        <div className={classes.settingCardTitleContainer}>
          <Typography variant="h6" className={classes.settingCardTitle}>{title}</Typography>
        </div>
        {children}
      </div>
    )
  }

  const IntegerSettingCard = ({title, value, valueLabel, minValue, maxValue, stepValue, unitLabel, onIncrement, onDecrement}) => {
    return (
      <SettingCard title={title}>
        <div className={classes.settingCardNumberAdjusterContentContainer}>
          <NumberAdjuster
            value={value}
            valueLabel={valueLabel}
            minValue={minValue}
            maxValue={maxValue}
            unitLabel={unitLabel}
            stepValue={stepValue}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
            disabled={isReadOnly}
          />
        </div>
      </SettingCard>
    )
  }

  /**
   * Same as IntegerSettingCard, except if activeLineSettings > pressureUnit is BAR, valueLabel is the value converted from PSI to BAR...
   */
  const SafePressureSettingCard = () => {

    const value = activeLineSettings[LINE_SETTINGS_KEYS.SAFE_PRESSURE_PSI.key];
    const pressureUnit = activeLineSettings[LINE_SETTINGS_KEYS.PRESSURE_UNITS.key]

    // If activeLineSettings > pressureUnit is BAR, construct label of value converted from PSI to BAR...
    const valueLabelOverride =
      pressureUnit === LINE_SETTINGS_KEYS.PRESSURE_UNITS.options.BAR.key ?
        round(
          convertPsiToBar(activeLineSettings[LINE_SETTINGS_KEYS.SAFE_PRESSURE_PSI.key]),
          2 // decimalPlaces...
        ) :
        value;

    return (
      <IntegerSettingCard
        title={translations.pages.lineSettings.safePressure}
        value={value}
        valueLabel={valueLabelOverride} // Override valueLabel...
        stepValue={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.SAFE_PRESSURE_PSI.key].interval}
        minValue={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.SAFE_PRESSURE_PSI.key].options[0]}
        maxValue={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.SAFE_PRESSURE_PSI.key].options[1]}
        unitLabel={LINE_SETTINGS_KEY_TO_LABEL_MAP[pressureUnit]}
        onDecrement={ (updatedValue) => { setActiveLineSettings(updateLineSettings(activeLineSettings, LINE_SETTINGS_KEYS.SAFE_PRESSURE_PSI.key, updatedValue)) }}
        onIncrement={ (updatedValue) => { setActiveLineSettings(updateLineSettings(activeLineSettings, LINE_SETTINGS_KEYS.SAFE_PRESSURE_PSI.key, updatedValue)) }}
      />
    )
  }

  const RadioGroupSettingCard = ({title, defaultValue, values, onChangeOverride, associatedLineSettingKey, additionalRadioGroupClasses= [], formControlLabelWidth = "100%"}) => {

    const DEFAULT_ON_CHANGE = (event) => setActiveLineSettings(updateLineSettings(activeLineSettings, associatedLineSettingKey, event.target.value));
    const localOnChange = onChangeOverride ? onChangeOverride : DEFAULT_ON_CHANGE; // If onChangeOverride is not supplied, use DEFAULT_ON_CHANGE as onChange handler...

    return (
      <SettingCard
        title={title}>

        <div className={classes.settingCardRadioGroupContentContainer}>
          <RadioGroup
            defaultValue={defaultValue}
            onChange={localOnChange}
            className={[classes.radioGroup, ...additionalRadioGroupClasses].join(' ')}>
            {
              values.map(value => {
                return (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<QuantifeelRadio disabled={isReadOnly}/>}
                    label={LINE_SETTINGS_KEY_TO_LABEL_MAP[value]}
                    className={classes.formControlLabel}
                    style={{width: formControlLabelWidth}} // Hack. Override width of formControlLabel, to get sane label ellipsis overflow for variety of SettingCard and RadioGroup options layouts...
                  />
                )
              })
            }
          </RadioGroup>
        </div>

      </SettingCard>
    )
  }

  /**
   * Same as RadioGroupSettingCard, except we want AutoRecordVideoSettingCard options to be
   * laid out a bit differently, so we define and pass an additional style class...
   */
  const AutoRecordVideoSettingCard = () => {

    const useStyles = makeStyles(theme => ({
      additionalRadioGroupClass: {
        paddingBottom: 64,
      },
    }))

    const classes = useStyles();

    return (
      <RadioGroupSettingCard
        title={translations.pages.lineSettings.autoRecordVideo.header}
        defaultValue={activeLineSettings[LINE_SETTINGS_KEYS.AUTO_RECORD_VIDEO.key]}
        values={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.AUTO_RECORD_VIDEO.key].options}
        associatedLineSettingKey={LINE_SETTINGS_KEYS.AUTO_RECORD_VIDEO.key}
        additionalRadioGroupClasses={[classes.additionalRadioGroupClass]}
        formControlLabelWidth={"50%"}
      />
    )
  }

  const renderTabs = () => {
    return (
      <TabContext value={selectedTab}>

        <TabList
          onChange={onTabClick}
          indicatorColor={null} // Remove the indicator color, to allow for desired styling...
        >
          {renderTab(TABS.ONE, translations.pages.lineSettings.unitSystem)}
          {renderTab(TABS.TWO, translations.pages.lineSettings.displayRecordSettings)}
          {renderTab(TABS.THREE, translations.pages.lineSettings.autoSeamerDetectionSettings)}
        </TabList>

        {activeLineSettings && lineSettingsDefaultsAndOptions && ( // Null check...
          <>

            {/* Unit System */}
            {renderTabPanel(
              TABS.ONE,
              <div className={classes.tabPanelContentContainer}>

                {/* Pressure */}
                <RadioGroupSettingCard
                  title={translations.common.pressure}
                  defaultValue={

                    activeLineSettings[LINE_SETTINGS_KEYS.IS_VIAL_MODE.key] &&
                    activeLineSettings[LINE_SETTINGS_KEYS.PREFER_PRESSURE_OR_FORCE.key] === LINE_SETTINGS_KEYS.PREFER_PRESSURE_OR_FORCE.options.FORCE.key ? // If is-vial-mode and prefer-pressure-or-force is force...

                      LINE_SETTINGS_KEYS.PRESSURE_UNITS.options.CONVERT_TO_FORCE.key : // Default value is CONVERT_TO_FORCE...
                      activeLineSettings[LINE_SETTINGS_KEYS.PRESSURE_UNITS.key] // Default value is active pressure-unit...

                  }
                  values={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.PRESSURE_UNITS.key].options}
                  onChangeOverride={
                    (event) => {

                      if (event.target.value === LINE_SETTINGS_KEYS.PRESSURE_UNITS.options.CONVERT_TO_FORCE.key) {
                        setActiveLineSettings(updateLineSettings(activeLineSettings, LINE_SETTINGS_KEYS.PREFER_PRESSURE_OR_FORCE.key, LINE_SETTINGS_KEYS.PREFER_PRESSURE_OR_FORCE.options.FORCE.key)) // prefer-pressure-or-force
                      } else {
                        let updatedLineSettings;
                        updatedLineSettings = updateLineSettings(activeLineSettings, LINE_SETTINGS_KEYS.PREFER_PRESSURE_OR_FORCE.key, LINE_SETTINGS_KEYS.PREFER_PRESSURE_OR_FORCE.options.PRESSURE.key) // prefer-pressure-or-force
                        updatedLineSettings = updateLineSettings(updatedLineSettings, LINE_SETTINGS_KEYS.PRESSURE_UNITS.key, event.target.value) // pressure-unit
                        setActiveLineSettings(updatedLineSettings)
                      }

                    }
                  }
                  associatedLineSettingKey={LINE_SETTINGS_KEYS.PRESSURE_UNITS.key}
                />

                {/* Force */}
                <RadioGroupSettingCard
                  title={translations.common.force}
                  defaultValue={activeLineSettings[LINE_SETTINGS_KEYS.FORCE_UNITS.key]}
                  values={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.FORCE_UNITS.key].options}
                  associatedLineSettingKey={LINE_SETTINGS_KEYS.FORCE_UNITS.key}
                />

                {/* Rotation */}
                <RadioGroupSettingCard
                  title={translations.common.rotation}
                  defaultValue={activeLineSettings[LINE_SETTINGS_KEYS.ROTATION_UNITS.key]}
                  values={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.ROTATION_UNITS.key].options}
                  associatedLineSettingKey={LINE_SETTINGS_KEYS.ROTATION_UNITS.key}
                />

                {/* Distance */}
                <RadioGroupSettingCard
                  title={translations.common.distance}
                  defaultValue={activeLineSettings[LINE_SETTINGS_KEYS.DISTANCE_UNITS.key]}
                  values={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.DISTANCE_UNITS.key].options}
                  associatedLineSettingKey={LINE_SETTINGS_KEYS.DISTANCE_UNITS.key}
                />

                {/* Temperature */}
                <RadioGroupSettingCard
                  title={translations.common.temperature}
                  defaultValue={activeLineSettings[LINE_SETTINGS_KEYS.TEMPERATURE_UNITS.key]}
                  values={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.TEMPERATURE_UNITS.key].options}
                  associatedLineSettingKey={LINE_SETTINGS_KEYS.TEMPERATURE_UNITS.key}
                />

                {/* Shock */}
                <RadioGroupSettingCard
                  title={translations.common.shock}
                  defaultValue={activeLineSettings[LINE_SETTINGS_KEYS.SHOCK_UNITS.key]}
                  values={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.SHOCK_UNITS.key].options}
                  associatedLineSettingKey={LINE_SETTINGS_KEYS.SHOCK_UNITS.key}
                />

                {/* Safe Pressure */}
                <SafePressureSettingCard/>

                {/* Safe Impact */}
                {!activeLineSettings[LINE_SETTINGS_KEYS.IS_VIAL_MODE.key] && ( // If not is-vial-mode, show Safe Impact card...

                  <IntegerSettingCard
                    title={translations.pages.lineSettings.safeImpact}
                    value={activeLineSettings[LINE_SETTINGS_KEYS.SAFE_IMPACT_IPS.key]}
                    stepValue={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.SAFE_IMPACT_IPS.key].interval}
                    minValue={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.SAFE_IMPACT_IPS.key].options[0]}
                    maxValue={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.SAFE_IMPACT_IPS.key].options[1]}
                    unitLabel={LINE_SETTINGS_KEY_TO_LABEL_MAP[LINE_SETTINGS_KEYS.SHOCK_UNITS.options.IPS.key]}
                    onDecrement={ (updatedValue) => { setActiveLineSettings(updateLineSettings(activeLineSettings, LINE_SETTINGS_KEYS.SAFE_IMPACT_IPS.key, updatedValue)) }}
                    onIncrement={ (updatedValue) => { setActiveLineSettings(updateLineSettings(activeLineSettings, LINE_SETTINGS_KEYS.SAFE_IMPACT_IPS.key, updatedValue)) }}
                  />

                )}

              </div>
            )}

            {/* Display and Record Settings */}
            {renderTabPanel(
              TABS.TWO,
              <div className={classes.tabPanelContentContainer}>

                {/* Realtime Feedback Display */}
                <RadioGroupSettingCard
                  title={translations.pages.lineSettings.realTimeFeedbackDisplay.header}
                  defaultValue={activeLineSettings[LINE_SETTINGS_KEYS.REAL_TIME_FEEDBACK_DISPLAY_MODE.key]}
                  values={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.REAL_TIME_FEEDBACK_DISPLAY_MODE.key].options}
                  associatedLineSettingKey={LINE_SETTINGS_KEYS.REAL_TIME_FEEDBACK_DISPLAY_MODE.key}
                />

                {/* Pressure/Force Display Mode */}
                <RadioGroupSettingCard
                  title={translations.pages.lineSettings.pressureForceDisplayMode.header}
                  defaultValue={activeLineSettings[LINE_SETTINGS_KEYS.PRESSURE_DISPLAY_MODE.key]}
                  values={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.PRESSURE_DISPLAY_MODE.key].options}
                  associatedLineSettingKey={LINE_SETTINGS_KEYS.PRESSURE_DISPLAY_MODE.key}
                />

                {/* Shock Display Mode */}
                <RadioGroupSettingCard
                  title={translations.pages.lineSettings.shockDisplayMode.header}
                  defaultValue={activeLineSettings[LINE_SETTINGS_KEYS.SHOCK_DISPLAY_MODE.key]}
                  values={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.SHOCK_DISPLAY_MODE.key].options}
                  associatedLineSettingKey={LINE_SETTINGS_KEYS.SHOCK_DISPLAY_MODE.key}
                />

                {/* Auto Record Video */}
                <AutoRecordVideoSettingCard/>

              </div>
            )}

            {/* Auto Seamer Detection Settings */}
            {renderTabPanel(
              TABS.THREE,
              <div className={classes.tabPanelContentContainer}>

                {/* Seamer Detection */}
                <RadioGroupSettingCard
                  title={translations.pages.lineSettings.seamerDetection.header}
                  defaultValue={activeLineSettings[LINE_SETTINGS_KEYS.SEAMER_DETECTION.key]}
                  values={lineSettingsDefaultsAndOptions[LINE_SETTINGS_KEYS.SEAMER_DETECTION.key].options}
                  associatedLineSettingKey={LINE_SETTINGS_KEYS.SEAMER_DETECTION.key}
                  formControlLabelWidth={"50%"}
                />

              </div>
            )}

          </>
        )}

      </TabContext>
    )
  }

  const renderFooter = () => {
    return (
      <PageFooter>
        <div className={classes.footerContentContainer}>

          {/* Cancel */}
          <div className={classes.footerContentContainerSubContainer}>
            <ProgressButton
              variant={PROGRESS_BUTTON_VARIANTS.SECONDARY}
              text={translations.common.cancel}
              onClick={onBack}
            />
          </div>

          {/* Save */}
          <div className={classes.footerContentContainerSubContainer}>
            <ProgressButton
              variant={PROGRESS_BUTTON_VARIANTS.PRIMARY}
              text={translations.common.save}
              disable={isReadOnly || !isLineSettingsDirty}
              onClick={() => setIsSaveConfirmationDialogOpen(true)}
            />
          </div>

        </div>
      </PageFooter>
    )
  }

  const renderSaveConfirmationDialog = () => {
    return (
      <SaveConfirmationDialog
        open={isSaveConfirmationDialogOpen}
        isSaving={isUpdatingLineSettings}
        onClose={() => setIsSaveConfirmationDialogOpen(false)}
        onCancel={() => setIsSaveConfirmationDialogOpen(false)}
        onSave={onSave}
      />
    )
  }

  return (
    <>
      {isLoading && <FullScreenCircularProgress/>}

      <div className={classes.root}>
        {renderHeader()}
        {renderTabs()}
        {renderFooter()}
        {renderSaveConfirmationDialog()}
      </div>
    </>
  );
};

export default LineSettings;