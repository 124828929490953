import * as React from "react";
import useStyles from './Styles.js';
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {SiteContext} from "../../../Context";
import translations from '../../../translations/en.json';
import {
  deepEqual,
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
} from '../../../shared/Utilities'
import PageHeader from '../../../shared/components/PageHeader/PageHeader'
import PageFooter from '../../../shared/components/PageFooter/PageFooter'
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from '../../../shared/components/ProgressButton/ProgressButton'
import {
  FIELD_TYPE_BOOLEAN,
  FIELD_TYPE_DECIMAL, FIELD_TYPE_INTEGER,
  FIELD_TYPE_POSITIVEDECIMAL, FIELD_TYPE_POSITIVEINTEGER,
  SST_PAGE_LIST_MACHINE_CENTERS
} from '../../../Constants'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getEntityPermissions } from '../../../query/entities/auth'
import { useRouteMatch } from 'react-router-dom'
import {
  getMachineCenter, getMachineCenterTemplates,
  updateMachineCenter
} from '../../../query/entities/machineCenters'
import SaveConfirmationDialog from '../../../shared/components/Dialogs/SaveConfirmationDialog/SaveConfirmationDialog'
import { useSnackbar } from 'notistack'
import { TextField, Typography } from '@mui/material'
import QuantifeelSvgIcon from '../../../shared/components/QuantifeelSvgIcon/QuantifeelSvgIcon'
import {ReactComponent as DeleteIcon} from '../../../img/icons/toggle_x.svg'
import {ReactComponent as AddIcon} from '../../../img/icons/add role.svg'
import FullScreenCircularProgress from '../../../shared/components/FullScreenCircularProgress'
import Form from 'react-bootstrap/Form'
import { getDuplicateMetadataKeys } from './Helpers'
import ReadOnlyNotification from '../../../shared/components/ReadOnlyNotification'
import { getLine } from '../../../query/entities/lines'
import {getCustomer} from "../../../query/entities/customers";

const pageTitle = translations.pages.machineCenterSettings.title;

const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_GET, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const MachineCenterSettings = (props) => {

  const {
    history
  } = props;

  const {currentCustomer, hasPermission} = useContext(SiteContext);

  const match = useRouteMatch();
  const queryClient = useQueryClient();
  const {enqueueSnackbar} = useSnackbar();

  const emptyMetadataObject = {key: '', value: '', type: ''}

  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [machineCenterId, setMachineCenterId] = useState(null);
  const [isSaveConfirmationDialogOpen, setIsSaveConfirmationDialogOpen] = React.useState(false);

  const [activeMachineCenterMetadata, setActiveMachineCenterMetadata] = useState([{...emptyMetadataObject}]); // Spread emptyMetadataObject to create copy and avoid reference issues...
  const [initialMachineCenterMetadata, setInitialMachineCenterMetadata] = useState([{...emptyMetadataObject}]); // Spread emptyMetadataObject to create copy and avoid reference issues...

  // ------------------------------
  // -- BEGIN useQuery / useMemo --
  // ------------------------------

  const {isLoading: isLoadingCustomer, data: customer= []} = useQuery(
      ['customer', {customerId: currentCustomer}],
      getCustomer
  );

  const {isLoading: isLoadingCustomerEntityPermissions, data: customerEntityPermissions} = useQuery(
    ['entityPermissions', currentCustomer, 'machineCenter', {ids: [machineCenterId]}],
    getEntityPermissions,
    { enabled: !!machineCenterId }
  );

  const {isLoading: isLoadingMachineCenter, data: machineCenter= {} } = useQuery(
    ['machineCenter', {machineCenterId: machineCenterId}], // This query key is invalidated on mutate, to force refetch of data...
    getMachineCenter,
    { enabled: !!machineCenterId }
  );

  const {isLoading: isLoadingLine, data: line= {} } = useQuery(
    ['line', {lineId: machineCenter.lineId}],
    getLine,
    { enabled: !!machineCenter?.lineId }
  );


  const {isLoading: isLoadingTemplate, data: machineCenterTemplates=[]} = useQuery(
      ['machineCenterTemplate', {}, {customerTypeId: customer.customerTypeId}],
      getMachineCenterTemplates,
      { enabled: !!customer?.customerTypeId }
  );

  /**
   * Mutator for updating machine center settings, given machine center settings...
   */
  const {mutate: mutateUpdateMachineCenter, isLoading: isUpdatingMachineCenter} = useMutation(updateMachineCenter, {
    onSuccess: (data, variables) => {

      // Close save confirmation dialog...
      setIsSaveConfirmationDialogOpen(false);

      // Invalidating useQuery key, to force refetch of data, following upsert...
      queryClient.invalidateQueries(['machineCenter', {machineCenterId: machineCenterId}]);

      // Show success...
      enqueueSnackbar(translations.pages.machineCenterSettings.machineCenterSettingsUpdated, {variant: 'success'});
    },
    onError: ({response: {data}}) => {

      // Close save confirmation dialog...
      setIsSaveConfirmationDialogOpen(false);

      // Show error...
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  });

  /**
   * Given changes to machineCenterId, permissions
   * Set isReadOnly...
   */
  const isReadOnly = useMemo(() => {
    return (
      !customerEntityPermissions?.machinecenter[machineCenterId]?.update
    )
  }, [machineCenterId, customerEntityPermissions])

  /**
   * Given changes to activeMachineCenterMetadata, initialMachineCenterMetadata
   * Determine if machine settings are dirty...
   */
  const isMachineCenterSettingsDirty = useMemo(() => {
    // If not deepEqual, then dirty...
    return !deepEqual(activeMachineCenterMetadata, initialMachineCenterMetadata);
  }, [activeMachineCenterMetadata, initialMachineCenterMetadata]);

  // ----------------------------
  // -- END useQuery / useMemo --
  // ----------------------------

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  /**
   * On component mount...
   */
  useEffect(() => {

    // Validate permissions...
    handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission)

    // Set page title..
    document.title = pageTitle;

    // Set machineCenterId...
    const machineCenterId = match.params.machineCenterId;
    setMachineCenterId(machineCenterId);

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to useQuery loading states...
   * Set isLoading...
   */
  useEffect(() => {
    if ( isLoadingCustomerEntityPermissions ||
         isLoadingMachineCenter ||
         isLoadingLine ||
         isLoadingCustomer ||
         isLoadingTemplate) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingCustomerEntityPermissions, isLoadingMachineCenter, isLoadingLine, isLoadingCustomer, isLoadingTemplate]);

  /**
   * Given changes to machineCenter...
   * Set activeMachineCenterMetadata, initialMachineCenterMetadata...
   */
  useEffect(() => {

    // If the machineCenter has metadata, set activeMachineCenterMetadata, initialMachineCenterMetadata, from default...
    if (machineCenter?.machineCenterMetadata) {
      const updatedActiveMachineCenterMetadata = JSON.parse(JSON.stringify(machineCenter.machineCenterMetadata)); // Deep copy, since object has deep nesting...

      if(machineCenterTemplates.length !== 0 && !!(machineCenter.machineCenterType)) {
        const templateMetadata = JSON.parse(JSON.stringify(machineCenterTemplates.find(a => a.id === machineCenter.machineCenterType.id))).metadata;
        const alreadyDefinedKeys = new Set(updatedActiveMachineCenterMetadata.map(obj => obj.key));

        // We want to populate the already defined metadata (metadata pulled from cloud) with their respective field type
        // Undefined or string means any value can be put in
        updatedActiveMachineCenterMetadata.forEach((d) => {
          let metadataWithType = templateMetadata.find((e) => e.key === d.key);
          if(alreadyDefinedKeys.has(metadataWithType?.key)) {
            // Define the type and error status for the already defined mandatory metadata
            d.type = metadataWithType.type;
            d.error = false;
          }
        })

        // Mandatory metadata that hasn't been defined yet
        const filteredArray = templateMetadata.filter(obj => !alreadyDefinedKeys.has(obj.key));
        filteredArray.forEach((t) => {
          // In the case where there are missing mandatory metadata, we need to defined a value and error field
          t.value = "";
          t.error = false;
          updatedActiveMachineCenterMetadata.push(t);
        })

      }

      setActiveMachineCenterMetadata(
        [...updatedActiveMachineCenterMetadata] // Copy to avoid reference issues, between activeMachineCenterMetadata and initialMachineCenterMetadata...
          .sort((a, b) => a.key.localeCompare(b.key)) // Sort by key, in ascending order...
      )

      setInitialMachineCenterMetadata(
        [...updatedActiveMachineCenterMetadata] // Copy to avoid reference issues, between activeMachineCenterMetadata and initialMachineCenterMetadata...
          .sort((a, b) => a.key.localeCompare(b.key)) // Sort by key, in ascending order...
      )
    }
  }, [machineCenter, machineCenterTemplates.length]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Give changes to activeMachineCenterMetadata...
   * If activeMachineCenterMetadata is empty, add an empty metadata object, to ensure there's always one KVP row ready for edit...
   */
  useEffect(() => {
    if (activeMachineCenterMetadata.length === 0) {
      setActiveMachineCenterMetadata( metadata => [...metadata, {...emptyMetadataObject}])
    }
  }, [activeMachineCenterMetadata]) // eslint-disable-line react-hooks/exhaustive-deps

  // --------------------
  // -- END useEffects --
  // --------------------

  // --------------------------
  // -- BEGIN event handlers --
  // --------------------------

  const onBack = () => {
    // If there is a page in history, go back, else, go to /list-machine-centers...
    return !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_LIST_MACHINE_CENTERS}`)
  }

  const onAddRow = () => {
    const updatedActiveMachineCenterMetadata = JSON.parse(JSON.stringify(activeMachineCenterMetadata)); // Deep copy, since object has deep nesting...
    updatedActiveMachineCenterMetadata.push({...emptyMetadataObject});
    setActiveMachineCenterMetadata(updatedActiveMachineCenterMetadata)
  }

  const onRemoveRow = (rowIndex) => {
    const updatedActiveMachineCenterMetadata = activeMachineCenterMetadata.filter((metadata, index) => index !== rowIndex);
    setActiveMachineCenterMetadata(updatedActiveMachineCenterMetadata);
  }

  const onRowKeyChange = (rowIndex, key) => {
    const updatedActiveMachineCenterMetadata = JSON.parse(JSON.stringify(activeMachineCenterMetadata)); // Deep copy, since object has deep nesting...
    updatedActiveMachineCenterMetadata[rowIndex].key = key;
    setActiveMachineCenterMetadata(updatedActiveMachineCenterMetadata);
  }

  const onRowValueChange = (rowIndex, value, type) => {
    let showErrorMessage = isMetadataValueValid(type, value);
    const updatedActiveMachineCenterMetadata = JSON.parse(JSON.stringify(activeMachineCenterMetadata)); // Deep copy, since object has deep nesting...
    updatedActiveMachineCenterMetadata[rowIndex].value = value;
    updatedActiveMachineCenterMetadata[rowIndex].error = !showErrorMessage;
    setActiveMachineCenterMetadata(updatedActiveMachineCenterMetadata);
  }

  const onSubmit = (event) => {
    // Get form validity...
    const isFormValid = formRef.current.checkValidity();

    // Check form validity...
    if (isFormValid) {
      // Valid. Show save confirmation dialog...
      setIsSaveConfirmationDialogOpen(true)
    } else {
      // Invalid. Show error messages...
      formRef.current.reportValidity();
    }
  }

  const onSave = () => {

    // Validate...
    // a. Validate that there are no duplicate metadata keys...
    const duplicateMetadataKeys = getDuplicateMetadataKeys(activeMachineCenterMetadata);
    let typeValidationError = false;
    activeMachineCenterMetadata.forEach((a) => {
      if(!isMetadataValueValid(a.type, a.value)) {
        typeValidationError = true;
      }
    });

    if (duplicateMetadataKeys.length > 0 || typeValidationError) {
      if(duplicateMetadataKeys.length > 0) {
        enqueueSnackbar(`${translations.pages.machineCenterSettings.duplicateKeysErrorMsg} ${duplicateMetadataKeys.join(', ')}`, {variant: 'error'});
      } else {
        enqueueSnackbar(`${translations.pages.machineCenterSettings.typeErrorMsg}`, {variant: 'error'});
      }
      setIsSaveConfirmationDialogOpen(false)
      return;
    }

    // Upsert machine center...
    const updatedMachineCenter = {
      ...machineCenter,
      machineCenterTypeId: machineCenter.machineCenterType?.id, // Set machineCenterTypeId, since required by update API, lest the API interpret our request as including setting machineCenterType to null...
      machineCenterMetadata: activeMachineCenterMetadata
    }
    // a. Upsert...
    mutateUpdateMachineCenter(updatedMachineCenter)
  }

  // Checks if the passed in metadata is mandatory
  const isMetadataMandatory = (metadata) => {
    if(!!(machineCenter.machineCenterType) && machineCenterTemplates.length !== 0) {
      const metadataFromTemplate = JSON.parse(JSON.stringify(machineCenterTemplates.find(a => a.id === machineCenter.machineCenterType?.id))).metadata; // Deep copy, since object has deep nesting...
      return !!(metadataFromTemplate.find(f => f.key === metadata.key));
    }
    return false;
  }

  const isMetadataValueValid = (type, value) => {
    const regexMap = {
      [FIELD_TYPE_INTEGER]: /^-?\d+$/,
      [FIELD_TYPE_POSITIVEINTEGER]: /^[1-9]\d*$/,
      [FIELD_TYPE_DECIMAL]: /^-?\d*[.,]?\d*$/,
      [FIELD_TYPE_POSITIVEDECIMAL]: /^(?:[1-9]\d*|0(?!(?:\.0+)?$))?(?:\.\d+)?$/
    };

    if (type === FIELD_TYPE_BOOLEAN) {
      return value.toLowerCase() === 'false' || value.toLowerCase() === 'true';
    }

    const regex = regexMap[type];
    return regex ? regex.test(value) : true;
  }

  const getErrorMessageBasedOnFieldType = (type, key) => {
    if(type === FIELD_TYPE_INTEGER) {
      return `${key} ${translations.pages.machineCenterSettings.fieldIntegerTypeErrorMsg}`
    } else if(type === FIELD_TYPE_POSITIVEINTEGER) {
      return `${key} ${translations.pages.machineCenterSettings.fieldPositiveIntegerTypeErrorMsg}`
    } else if(type === FIELD_TYPE_DECIMAL) {
      return `${key} ${translations.pages.machineCenterSettings.fieldDecimalTypeErrorMsg}`
    } else if(type === FIELD_TYPE_POSITIVEDECIMAL) {
      return `${key} ${translations.pages.machineCenterSettings.fieldPositiveDecimalTypeErrorMsg}`
    } else if(type === FIELD_TYPE_BOOLEAN) {
      return `${key} ${translations.pages.machineCenterSettings.fieldBooleanTypeErrorMsg}`
    }
    return true;
  }

  // ------------------------
  // -- END event handlers --
  // ------------------------

  // -------------------
  // -- BEGIN renders --
  // -------------------

  const classes = useStyles()

  const renderHeader = () => {
    return (
      <PageHeader
        onBack={onBack}
        pageTitle={`${machineCenter.name} ${translations.pages.lineSettings.settings}`}
      />
    )
  }

  const renderFooter = () => {
    return (
      <PageFooter>
        <div className={classes.footerContentContainer}>

          {/* Cancel */}
          <div className={classes.footerContentContainerSubContainer}>
            <ProgressButton
              variant={PROGRESS_BUTTON_VARIANTS.SECONDARY}
              text={translations.common.cancel}
              onClick={onBack}
            />
          </div>

          {/* Save */}
          <div className={classes.footerContentContainerSubContainer}>
            <ProgressButton
              variant={PROGRESS_BUTTON_VARIANTS.PRIMARY}
              text={translations.common.save}
              disable={isReadOnly || !isMachineCenterSettingsDirty}
              onClick={onSubmit}
            />
          </div>

        </div>
      </PageFooter>
    )
  }

  const renderReadOnlyNotification = () => {
    return (
      <div className={classes.readOnlyNotificationContainer}>
        <ReadOnlyNotification message={translations.common.insufficientPermissionsToUpdateSettings}/>
      </div>
    )
  }

  const renderMetadataTable = () => {
    return (
      <div className={classes.metadataTableContainer}>

        {/* Header */}
        <div className={classes.metadataTableHeader}>
          <Typography variant="h6">{translations.pages.machineCenterSettings.metadata}</Typography>
          <AddRow
            disabled={isReadOnly}
            onClick={onAddRow}
          />
        </div>

        {/* Table */}
        <div className={classes.metadataTable}>

          {/* Table Header */}
          <div className={classes.metadataTableRow}>
            <Typography className={classes.metadataCell}>{translations.pages.machineCenterSettings.metadataTableKey}</Typography> {/* Key */}
            <Typography className={classes.metadataCell}>{translations.pages.machineCenterSettings.metadataTableValue}</Typography> {/* Value */}
            <div className={classes.metadataActionsCell}/> {/* Actions */} {/* Including this <div/> to simplify spacing. */}
          </div>

          {/* Table Rows */}
          <Form
            className={classes.metadataTableForm}
            id={"metadataForm"}
            autoComplete={"off"}
            ref={formRef}
            onSubmit={onSubmit}
          >

            {activeMachineCenterMetadata.map((metadata, index) => (

              <div key={index} className={classes.metadataTableRow}>

                {/* Key */}
                <TextField
                  required
                  disabled={isReadOnly || isMetadataMandatory(metadata)}
                  className={`${classes.metadataCell} ${classes.metadataDataCell}`}
                  InputProps={{
                    className: classes.metadataCellInput
                  }}
                  placeholder={translations.pages.machineCenterSettings.keyPlaceholder}
                  value={metadata.key}
                  onChange={(event) => onRowKeyChange(index, event.target.value)}
                />

                {/* Value */}
                <div className={classes.metadataErrorMessageContainer}>
                  <TextField
                      required
                      disabled={isReadOnly}
                      className={`${classes.metadataCell} ${classes.metadataDataCell}`}
                      InputProps={{
                        className: classes.metadataCellInput
                      }}
                      placeholder={translations.pages.machineCenterSettings.valuePlaceholder}
                      value={metadata.value}
                      onChange={(event) => onRowValueChange(index, event.target.value, metadata.type)}
                  />
                  {metadata.error && <Typography className={classes.metadataErrorMessage}>{getErrorMessageBasedOnFieldType(metadata.type, metadata.key)}</Typography>}
                </div>

                {/* Actions */}
                <div className={classes.metadataActionsCell}>
                  {/* We only allow the removal of rows that have not yet been persisted, represented by a missing metadata.id
                      Therefore, only show remove button for rows that have not yet been persisted / have missing metadata.id */}
                  { (!metadata.id && !isMetadataMandatory(metadata)) && (
                    <QuantifeelSvgIcon
                      disabled={isReadOnly}
                      className={classes.deleteIcon}
                      component={DeleteIcon}
                      viewBox="0 0 16 16"
                      tooltipTitle={translations.pages.machineCenterSettings.removeRow}
                      onClick={() => onRemoveRow(index)}
                    />
                  )}
                </div>
              </div>

            ))}

          </Form>

        </div>
      </div>
    )
  }

  const AddRow = ({onClick}) => {
    return (
      <QuantifeelSvgIcon
        disabled={isReadOnly}
        component={AddIcon}
        viewBox={'0 0 48 48'}
        className={classes.addIcon}
        tooltipTitle={translations.pages.machineCenterSettings.addRow}
        onClick={onClick}
      />
    )
  }

  const renderSaveConfirmationDialog = () => {
    return (
      <SaveConfirmationDialog
        open={isSaveConfirmationDialogOpen}
        isSaving={isUpdatingMachineCenter}
        onClose={() => setIsSaveConfirmationDialogOpen(false)}
        onCancel={() => setIsSaveConfirmationDialogOpen(false)}
        onSave={onSave}
      />
    )
  }

  return (
    <>
      {isLoading && <FullScreenCircularProgress/>}

      <div className={classes.root}>
        {renderHeader()}
        {isReadOnly && renderReadOnlyNotification()}
        {renderMetadataTable()}
        {renderFooter()}
        {renderSaveConfirmationDialog()}
      </div>
    </>
  );
};

export default MachineCenterSettings;