import React, {useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {SiteContext} from "../../Context";
import {Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {DATE_TIME_TIMEZONE_FORMAT, formatBytes, handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_UPDATE} from "../../shared/Utilities";
import queryString from "query-string";
import ProgressWrapper from "../../shared/components/ProgressWrapper";
import ProtectedMoment from "../../shared/components/ProtectedMoment/ProtectedMoment";
import ProgressButton from "../../shared/components/ProgressButton/ProgressButton";
import {BASE_SST_API_URL, SST_API_PATH_LINES, SST_API_PATH_RUNS} from "../../Constants";
import ImageFromServer from "../../shared/components/ImageFromServer/ImageFromServer";
import CopyLabel from "../../shared/components/CopyLabel/CopyLabel";
import PageHeader from "../../shared/components/PageHeader/PageHeader";

import {deleteRun, getRunDetails, queueRunForExtraction, queueRunForLinemapGeneration} from '../../query/queries'

const pageTitle = 'Run Details';
const acceptablePagePermission = [
  {entity: 'Run', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    overflowY:'auto',
  },
  content: {
    display: 'flex',
    margin: '20px 30px 10px',
    borderBottom: '1px solid #615E5E',

    '&>*': {
      flexGrow: '1'
    }
  },
  group: {
    marginRight: '20px'
  },
  controlContainer: {
    margin: '20px 30px 10px',
  },
  button:{
    marginRight: '10px',
    marginBottom: '10px'
  },
  imageContainer: {
    maxWidth: '600px',
    padding: '0 30px 30px',
    '& img': {
      width: '100%'
    }
  },
  linearLoad: {
    margin:'10px 0 26px 0',
    width: '250px'
  }
}));

const RunDetails = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const {setBreadcrumbs, userDetails, hasPermission} = useContext(SiteContext);
  const [runId, setRunId] = useState("");

  const [linemapUrl, setLinemapUrl] = useState("");

  const {isLoading: isLoadingRunDetails, data:runDetails} = useQuery(['runDetails', {runId: runId}], getRunDetails, {enabled: !!runId});
  const {isLoading: isQueueingForExtraction, mutate: queueForExtraction} = useMutation(queueRunForExtraction, {
    onSuccess: () => {
      enqueueSnackbar('Queued For Extraction', {variant: 'success'})
    },
    onError: () => {
      enqueueSnackbar('Unable to Queue for Extraction', {variant: 'error'})
    }
  });
  const {isLoading: isQueueingForLinemapGeneration, mutate: queueForLinemapGeneration} = useMutation(queueRunForLinemapGeneration, {
    onSuccess: () => {
      enqueueSnackbar('Queued for Linemap Generation', {variant: 'success'})
    },
    onError: () => {
      enqueueSnackbar('Unable to Queue for Linemap Generation', {variant: 'error'})
    }
  });

  const {isLoading: isDeletingRun, mutate: mutateDelete} = useMutation(deleteRun, {
    onSuccess: () => {
      enqueueSnackbar('Run Deleted', {variant: 'success'})
    },
    onError: () => {
      enqueueSnackbar('Unable To Delete Run', {variant: 'error'})
    }
  })

  useEffect(() => {
      document.title = "View Run";
      const qs = queryString.parse(props.location.search);
      
      if (qs.id) {
          setRunId(qs.id);
      }
      setBreadcrumbs([{title: "View Run"}]);
      
  }, [props.location.search, setBreadcrumbs,]);

  
  const classes = useStyles();

  const generateLinemapForRun = () => {
      setLinemapUrl(BASE_SST_API_URL + SST_API_PATH_RUNS + '/' + runId + '/widgets?type=linemap');
  };

  const generateLatestLinemapForLine = () => {
      setLinemapUrl(BASE_SST_API_URL + SST_API_PATH_LINES + '/' + runDetails.lineId + '/linemap/latest');
  };

  const handleQueueForExtraction = () => {
    queueForExtraction(runDetails.runId);
  };

  const handleQueueForLineMapGeneration = () => {
    queueForLinemapGeneration(runDetails.runId);
  };

  const handleDeleteRun = () => {
    if (window.confirm(`Are you sure you want to delete this run?`)) {
      mutateDelete(runDetails.runId);
    }   
  };

  return (
    <div className={classes.root} data-testid="view-run-page">
      {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
        <div>
          <PageHeader pageTitle="Run Details" onBack={() => !!(history.location.key) ? history.goBack() : history.push(`/list-runs?lineId=${runDetails.lineId}`)}/>
          <div className={classes.content}>
            <div className={classes.group}>
              <Typography variant="h5">Run Name</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}>
                <Typography paragraph>{runDetails?.name}</Typography>
              </ProgressWrapper>

              <Typography variant="h5">Virtual Id</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph component="div">
                <CopyLabel value={runDetails?.virtualRunId} >{runDetails?.virtualRunId}</CopyLabel>
              </Typography>
              </ProgressWrapper>

              <Typography variant="h5">Run Id</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph component="div">
                <CopyLabel value={runDetails?.runId} >{runDetails?.runId}</CopyLabel>
              </Typography>
              </ProgressWrapper>

              <Typography variant="h5">Line Id</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph component="div">
                <CopyLabel value={runDetails?.lineId} >{runDetails?.lineId}</CopyLabel>
              </Typography>
              </ProgressWrapper>

              <Typography variant="h5">Duration</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph>
                <ProtectedMoment date={runDetails?.uploadDateStarted} add={{seconds: runDetails?.duration}} duration={runDetails?.uploadDateStarted} />
              </Typography>
              </ProgressWrapper>

              <Typography variant="h5">Run Download Available</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph> {runDetails?.downloadAvailable.toString()}</Typography></ProgressWrapper>

              {hasPermission('Admin', 'update', 'platform') && <Typography variant="h5">Path</Typography>}
              {hasPermission('Admin', 'update', 'platform') && <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph component="div">
                  <a
                    href={`https://s3.console.aws.amazon.com/s3/buckets/${runDetails?.s3Bucket}?region=us-west-2&prefix=${runDetails?.s3Path}`}
                    target="_blank"
                    rel="noreferrer">
                    S3 Bucket
                  </a>
                </Typography></ProgressWrapper>}
            </div>

            <div className={classes.group}>
              <Typography variant="h5">Size</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph> {formatBytes(runDetails?.size)}</Typography></ProgressWrapper>

              <Typography variant="h5">Date Upload Started</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph component={'div'}>
                <ProtectedMoment date={runDetails?.uploadDateStarted} format={DATE_TIME_TIMEZONE_FORMAT} tz={userDetails.user.tz}/>
              </Typography></ProgressWrapper>

              <Typography variant="h5">Date Upload Ended</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph component="div">
                <ProtectedMoment date={runDetails?.uploadDateEnded} format={DATE_TIME_TIMEZONE_FORMAT} tz={userDetails.user.tz}/>
              </Typography></ProgressWrapper>

              <Typography variant="h5">Date Extracted</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph component="div">
                {
                  runDetails?.extractionDate
                    ? <div>
                        <ProtectedMoment
                          date={runDetails?.extractionDate}
                          format={DATE_TIME_TIMEZONE_FORMAT}
                          tz={userDetails.user.tz} />
                        <br/>
                        <span>
                        Extracted <ProtectedMoment date={runDetails?.extractionDate} duration={runDetails?.uploadDateEnded} /> after upload
                        </span>
                      </div>
                    : "Not Extracted"
                }
              </Typography></ProgressWrapper>

              <Typography variant="h5">Upload Status</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph> {runDetails?.uploadStatus}</Typography></ProgressWrapper>

              <Typography variant="h5">Upload Type</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph> {runDetails?.uploadType}</Typography></ProgressWrapper>

              <Typography variant="h5">Software Version</Typography>
              <ProgressWrapper linear isLoading={isLoadingRunDetails} linearClass={classes.linearLoad}><Typography paragraph> {runDetails?.uploadSoftwareVersion || 'Not Available'}</Typography></ProgressWrapper>
            </div>
          </div>


          <div className={classes.controlContainer}>
            <Typography variant="h5" paragraph>Controls</Typography>
            <div>
              {hasPermission('Admin', 'update', 'platform') && <ProgressButton isLoading={isQueueingForExtraction} onClick={handleQueueForExtraction} text={'Queue for Extraction'} className={classes.button} />}
              {hasPermission('Admin', 'update', 'platform') && <ProgressButton isLoading={isQueueingForLinemapGeneration} onClick={handleQueueForLineMapGeneration} text={'Queue for Linemap Generation'} className={classes.button} />}

              {hasPermission('Admin', 'update', 'platform') && <ProgressButton isLoading={false} onClick={generateLinemapForRun} text={'Show Linemap Widget'} className={classes.button} />}
              {hasPermission('Admin', 'update', 'platform') && <ProgressButton isLoading={false} onClick={generateLatestLinemapForLine} text={'Show Run Linemap'} className={classes.button} />}

              {hasPermission('Run', 'delete') && <ProgressButton isLoading={isDeletingRun} onClick={handleDeleteRun} text={'Delete Run'} className={classes.button} />}
            </div>
          </div>
          <div className={classes.imageContainer}>
            {linemapUrl && <div><ImageFromServer fetchUrl={linemapUrl} /></div>}
          </div>
        </div>
      }
    </div>
  );
};

export default RunDetails;